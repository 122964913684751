import getAPIMap from "../api_map.js";
import axios from "axios";
import { roleName } from "../constants_map";
// const IP = process.env.REACT_APP_IP;
import getIP from "../getIP.js";
const IP = getIP();

/**
 * This method is used to get the web notifications of the user
 */

const getNotifications = async ({ queryKey, pageParam = 1 }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${queryKey[1].token}`,
    },
  };

  var url =
    IP + getAPIMap("getNotifications") + `?pgSize=${50}&pgIndex=${pageParam}`;
  //var url = IP + getAPIMap("getNotifications") + `?paginate=0`;

  url = url.concat("&userId=", queryKey[2]);
  if (queryKey[3]) {
    url = url.concat("&from=", queryKey[3]);
  }
  if (queryKey[4]) {
    url = url.concat("&search=", queryKey[4]);
  }
  if (queryKey[5]) {
    url = url.concat("&startDate=", queryKey[5]);
  }
  if (queryKey[6]) {
    url = url.concat("&endDate=", queryKey[6]);
  }
  // return { response, nextPage: pageParam + 1 };
  let response = await axios.get(url, config);
  return { response, nextPage: pageParam + 1 };
};

/**
 * This method is used to get the unread notification count
 */

const getUnreadCount = async ({ queryKey }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${queryKey[1].token}`,
    },
  };

  var url = IP + getAPIMap("unreadNotificationCount");
  url = url.concat("?userId=", queryKey[2]);
  return await axios.get(url, config);
};

/**
 * This method is used to send notification to the required user
 */
const sendNotifications = async (options) => {
  const config = {
    headers: {
      Authorization: `Bearer ${options.userDetails.token}`,
    },
  };

  var url = IP + getAPIMap("sendNotifications");
  return await axios.post(url, options.req, config);
};

/**
 * This method is used to delete notifications */
const deleteNotifications = async (options) => {
  const config = {
    headers: {
      Authorization: `Bearer ${options.userDetails.token}`,
    },
  };

  var url = IP + getAPIMap("deleteNotification");
  return await axios.post(url, options.req, config);
};

/**
 * This method is used to update notification status
 */
const updateNotificationStatus = async (options) => {
  const config = {
    headers: {
      Authorization: `Bearer ${options.userDetails.token}`,
    },
  };

  var url = IP + getAPIMap("updateNotification");
  return await axios.put(url, options.req, config);
};

/**
 * This method is used to get the topics
 */

const getTopics = async ({ queryKey }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${queryKey[1].token}`,
    },
  };

  let role = queryKey[1].realm_access.roles[0].split("~");

  var url = IP + getAPIMap("getTopics");
  if (role[2] === roleName.platformAdmin) {
    url = url + "?visibility=platform&access_level=public";
  } else if (role[2] === roleName.enterpriseAdmin) {
    url = url + "?visibility=enterprise_admin";
  } else if (role[2] === roleName.enterpriseUser) {
    url = url + "?visibility=enterprise";
  }

  return await axios.get(url, config);
};

/**
 * This method is used to get the subscribed topics of user
 */

const getSubscribedTopics = async ({ queryKey }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${queryKey[1].token}`,
    },
  };

  var url = IP + getAPIMap("getsubscribedTopics");
  url = url.replace("{uid}", queryKey[1].sub);
  return await axios.get(url, config);
};

/**
 *
 *This method is used to subscribe to topics
 */
const subscribeToTopics = async (options) => {
  const config = {
    headers: {
      Authorization: `Bearer ${options.userDetails.token}`,
    },
  };
  var url = IP + getAPIMap("subscribeTopic");
  return await axios.post(url, options.req, config);
};

export {
  getNotifications,
  sendNotifications,
  deleteNotifications,
  updateNotificationStatus,
  getUnreadCount,
  getTopics,
  subscribeToTopics,
  getSubscribedTopics,
};
