import { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
//import SmsTemplatesList from "../pages/smsTemplatesList";

const Login = lazy(() => import("../pages/Login"));
// const CreateAccount = lazy(() => import("../pages/CreateAccount"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword.js"));
const SignUp = lazy(() => import("../pages/SignUp"));
const EmailNotification = lazy(() => import("../pages/EmailNotification"));
const ReEmailNotification = lazy(() => import("../pages/ReEmailNotification"));
const Success = lazy(() => import("../pages/Success"));
const VerifyNumber = lazy(() => import("../pages/VerifyNumber"));
const SetPassword = lazy(() => import("../pages/SetPassword"));
const OtpVerification = lazy(() => import("../pages/OtpVerification"));
const OtpSuccessPage = lazy(() => import("../admin/pages/SuccessOtp"));
const OtpFailurePage = lazy(() => import("../admin/pages/FailureOtp"));
const MfaOtp = lazy(() => import("../admin/pages/MfaOtp.js"));
const RePassword = lazy(() => import("../pages/RePassword"));
const TermsAndConditions = lazy(() => import("../pages/Terms-and-conditions"));
// const EnterpriseCreation = lazy(() => import("../pages/EnterpriseCreation"));
// const CreateDetailedReports = lazy(() =>
//   import("../pages/CreateDetailedReport")
// );
// const SmsTemplatesList = lazy(() => import("../pages/smsTemplatesList"));
const AdminLogin = lazy(() => import("../admin/pages/login"));
function Auth() {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="admin/login" element={<AdminLogin />} />
      <Route path="signup" element={<SignUp />} />
      <Route path="email-notification" element={<EmailNotification />} />
      <Route path="reemail-notification" element={<ReEmailNotification />} />
      {/* <Route path="create-account" element={<CreateAccount />} /> */}
      <Route path="forgot-password" element={<ForgotPassword />} />
      {/* <Route exact path="/auth/smsDashboard" component={SMSDashboard} /> */}
      {/* <Route exact path="/auth/reset-password" component={ResetPassword} /> */}
      <Route path="success" element={<Success />} />
      <Route path="verify-number" element={<VerifyNumber />} />
      <Route path="set-password" element={<SetPassword />} />
      <Route path="otp-verification" element={<OtpVerification />} />
      {/* <Route path="template" element={<SmsTemplatesList />} /> */}
      <Route path="reset-password" element={<RePassword />} />
      {/* <Route path="enterprise" element={<EnterpriseCreation />} /> */}
      {/* <Route path="report" element={<CreateDetailedReports />} /> */}
      <Route
        path="Terms-and-condition/:action"
        element={<TermsAndConditions />}
      />
      <Route path="/" element={<Navigate to="/auth/login" />} />
    </Routes>
  );
}

export default Auth;
