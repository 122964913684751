/* eslint-disable no-unused-vars */
import { useContext } from "react";
import routes from "../../routes/sidebar";
import MENU from "./Menu";
import { SidebarContext } from "../../context/SidebarContext";
import { ReactComponent as Closeicon } from "../../icons/leftArrow.svg";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { useQuery } from "react-query";
import getAPIMap from "../../api_map.js";
import LeftArrow from "../../assets/img/Icon ionic-ios-arrow-down.svg";
import { RightArrow } from "../../icons/index.js";
import getIP from "../../getIP.js";
const IP = getIP();
import { useTheme } from "../../context/ThemeContext.js";

function SidebarContent() {
  const { closeSidebar } = useContext(SidebarContext);
  const { userDetails } = useContext(AuthContext);
  const { collapsed, setCollapsed } = useContext(AuthContext);
  const { theme } = useTheme();
  //const [twoLines, setTwoLines] = useState(false);
  let menudata = [];
  let isLineAdded = false;

  const { data, isLoading } = useQuery(["/menu", userDetails], async () => {
    const URL = IP + getAPIMap("menu");

    const config = {
      headers: {
        Authorization: `Bearer ${userDetails.token}`,
      },
    };
    return await axios.get(URL, config);
  });
  if (!isLoading) {
    menudata = data?.data?.data.filter((e) => e.name !== "_Chat");
  }
  const hexToRgb = (hex) => {
    // Remove the leading '#' if present
    hex = hex?.replace(/^#/, "");

    // Parse the hex value into its RGB components
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return `${r}, ${g}, ${b}`;
  };
  return (
    <div
      style={{ backgroundColor: theme.sideMenuColor }}
      className={
        collapsed
          ? `w-[70px] h-100vh rounded-[15px] pb-[28px] flex flex-col fixed top-0 lg:top-[105px] bottom-0 lg:bottom-5 overflow-y-scroll`
          : `w-[201px] h-100vh  rounded-[15px] pb-[28px] flex flex-col fixed top-0 lg:top-[105px] bottom-0 lg:bottom-5 overflow-y-scroll`
      }
    >
      <Closeicon
        onClick={closeSidebar}
        className="h-[20px] w-[20px] fixed top-[15px] ltr:left-[170px] rtl:right-[170px] lg:hidden cursor-pointer"
      />

      <div className=" sticky top-24 ml-auto w-3   ">
        <button
          className="  w-3 h-4 justify-center rounded flex place-items-center bg-red-500 "
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          {!collapsed ? (
            <img src={LeftArrow} className="h-2 w-2" alt="arrow" />
          ) : (
            <RightArrow className="h-2 w-2" style={{ color: "white" }} />
          )}
        </button>
      </div>
      {/* if 'routes', change below 'routes' to 'menudata' after testing */}
      {menudata?.map((route, i) => {
        if (route.name) {
          isLineAdded = false;

          return <MENU route={route} key={`${route.name}${i}`} />;
        } else {
          if (!isLineAdded) {
            isLineAdded = !isLineAdded;
            return (
              <div
                className="mt-[5px] mb-[5px]  px-6 py-3"
                key={`${route.name}${i}`}
              >
                <hr
                  style={{
                    borderTop: `1px solid rgba(${hexToRgb(
                      theme?.menuTextColor
                    )}, 0.2)`,
                  }}
                ></hr>
              </div>
            );
          } else {
            return <div key={i}></div>;
          }
        }
      })}
    </div>
  );
}

export default SidebarContent;
