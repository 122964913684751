//import React from "react";

function Main({ children }) {
  return (
    <main className="h-full overflow-y-auto ">
      {/* <div className="md:container md:mx-auto grid sm:px-6 ">{children}</div> */}
      <div
        className="2xl:container lg:mx-auto grid"
        style={{ minHeight: "28rem" }}
      >
        {children}
      </div>
    </main>
  );
}

export default Main;
