import { useContext } from "react";

import Sidebar from "./Sidebar";

import { AuthContext } from "../../context/AuthContext";
//import LeftArrow from "../../assets/img/Icon ionic-ios-arrow-down.svg";
import { useTheme } from "../../context/ThemeContext";
function DesktopSidebar(/*props*/) {
  const { collapsed } = useContext(AuthContext);
  const { theme } = useTheme();
  return (
    <aside
      style={{ backgroundColor: theme.sideMenuColor }}
      className={
        collapsed
          ? `flex-shrink-0 hidden w-[70px] overflow-y-auto    lg:block`
          : `flex-shrink-0 hidden w-[201px] overflow-y-auto  lg:block`
      }
    >
      <Sidebar />
    </aside>
  );
}

export default DesktopSidebar;
