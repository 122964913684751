import { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
const ThemeContext = createContext();
export const useTheme = () => useContext(ThemeContext);
import getIP from "../getIP.js";
const IP = getIP();
import { useQuery } from "react-query";
import { getGlobalConfigNoAuth } from "../lib/login-api.js";

export const ThemeProvider = ({ children }) => {
  const { data: configData, isLoading: isAuthConfigLoading } = useQuery(
    ["noAuthConfig"],
    getGlobalConfigNoAuth,
    {
      onError: (err) => {
        console.log("error while getting config no auth: ", err);
      },
    }
  );
  const [theme, setTheme] = useState({
    font: "Nunito",
    logos: `${IP}/static/media/comviva-logo.45738fc35a5d95d312d062c1f0ff94cf.svg`,
    loginLogo: `${IP}/static/media/comviva-logo.45738fc35a5d95d312d062c1f0ff94cf.svg`,
    enableLoginLogo: "Enable",
    primaryColor: "#E00800",
    secondaryColor: "#353ef2",
    tertiaryColor: "#777578",
    sideMenuColor: "#2d2e42",
    backgroundColor: "#f5f5fa",
    tncUrl: ``,
    policyUrl: ``,
    backgroundImageLandingPage: `${IP}/static/media/comviva-background.18edf10ea57d4ea52fba.jpg`,
    announcementText: "Hello, Brand Setting page is being defined.........",
    announcementUrl: IP,
    announcementFlag: "Enable",
    faviconUrl: "https://ngagecpaas.com/favicon.ico",
    menuTextColor: "#FFFFFF",
    menuTextColorOnHover: "#ED1C24",
    radioCheckboxColor: "#353ef2",
    enablePolicy: "Disable",
    enableTerms: "Disable",
    selectedLanguages: [
      {
        title: "English",
        code: "en",
      },
    ],
    enableSignUp: "Enable",
    footer: "All rights reserved.",
    display: "",
    url: "",
    // primaryColor: "#4B0F1E",
    // secondaryColor: "#E00800",
    // fontFamily: "SuisseIntl",
    // tertiaryColor: "#232323",
    // sideMenuColor: "#4B0F1E",
    // backgroundColor: "white",

    // Add more theme properties as needed
  });
  // useEffect(()=>{
  //   sessionStorage.setItem('theme', JSON.stringify(theme));
  // },[])

  const { refetch } = useQuery(["getBrandList"], fetchColorsFromApi, {
    enabled: !isAuthConfigLoading,
    onSuccess: (data) => {
      if (data) {
        let defaultSettings = {
          font: "Nunito",
          logos: `${IP}/static/media/comviva-logo.45738fc35a5d95d312d062c1f0ff94cf.svg`,
          loginLogo: `${IP}/static/media/comviva-logo.45738fc35a5d95d312d062c1f0ff94cf.svg`,
          enableLoginLogo: "Enable",
          primaryColor: "#E00800",
          secondaryColor: "#353ef2",
          tertiaryColor: "#777578",
          sideMenuColor: "#2d2e42",
          backgroundColor: "#f5f5fa",
          radioCheckboxColor: "#353ef2",
          tncUrl: ``,
          policyUrl: ``,
          backgroundImageLandingPage: `${IP}/static/media/comviva-background.18edf10ea57d4ea52fba.jpg`,
          announcementText:
            "Hello, Brand Setting page is being defined.........",
          announcementUrl: IP,
          announcementFlag: "Enable",
          faviconUrl: "https://ngagecpaas.com/favicon.ico",
          menuTextColor: "#FFFFFF",
          menuTextColorOnHover: "#ED1C24",
          enablePolicy: "Disable",
          enableTerms: "Disable",
          selectedLanguages: [
            {
              title: "English",
              code: "en",
            },
          ],
          enableSignUp: "Enable",
          footer: "All rights reserved.",
          display: "",
          url: "",
        };
        let color = data?.data?.[0]?.brandSettingJson || defaultSettings;
        document.body.style.backgroundColor = color.backgroundColor;
        let bflag =
          configData?.find((e) => e.key === "bflag")?.value || "false";
        let defaultStyle =
          configData?.find((e) => e.key === "defaultStyleFlag")?.value ||
          "true";
        let enableBackgroundLogo =
          configData?.find((e) => e.key === "enableBackgroundLogo")?.value ||
          "true";
        let flags = {};
        flags.bflag = bflag;
        flags.defaultStyle = defaultStyle;
        flags.enableBackgroundLogo = enableBackgroundLogo;

        const linkElement =
          document.querySelector("link[rel*='icon']") ||
          document.createElement("link");
        linkElement.type = "image/png";
        linkElement.rel = "icon";
        linkElement.href = color.faviconUrl;
        document.head.appendChild(linkElement);

        setTheme({ ...defaultSettings, ...color, ...flags });

        sessionStorage.setItem(
          "theme",
          JSON.stringify({ ...defaultSettings, ...color, ...flags })
        );
        setIsThemeLoading(false);
      } else {
        let res = sessionStorage.getItem("theme");
        if (!res) {
          sessionStorage.setItem("theme", JSON.stringify(theme));
        } else {
          setTheme(JSON.parse(res));
        }
        setIsThemeLoading(false);
      }
    },
    onError: () => {
      let res = sessionStorage.getItem("theme");
      if (!res) {
        sessionStorage.setItem("theme", JSON.stringify(theme));
      } else {
        setTheme(JSON.parse(res));
      }

      setIsThemeLoading(false);
    },
  });

  const [isThemeLoading, setIsThemeLoading] = useState(true);

  return (
    <ThemeContext.Provider
      value={{ theme, setTheme, isThemeLoading, isAuthConfigLoading, refetch }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
async function fetchColorsFromApi() {
  const IP = getIP();
  const response = await axios(`${IP}` + "/api/v1/config/brand");

  return response?.data;

  // Assuming the API returns an object with color values
}
