import { useContext } from "react";

import Sidebar from "./Sidebar";

import { SidebarContext } from "../../context/SidebarContext";

function MobileSidebar() {
  const { isSidebarOpen } = useContext(SidebarContext);

  return (
    <div
      className={`${
        isSidebarOpen
          ? "bg-black fixed inset-0 z-40 bg-opacity-30 w-full lg:hidden"
          : ""
      }`}
    >
      <aside
        className={`fixed z-50  inset-y-0  flex-shrink-0 w-[201px] left-0  transition duration-500 ease-in-out ${
          isSidebarOpen ? " " : "-translate-x-56 "
        }  dark:bg-gray-800 `}
      >
        <Sidebar />
      </aside>
    </div>
  );
}

export default MobileSidebar;
