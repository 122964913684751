import React, { useState, useMemo, useEffect } from "react";
import dayjs from "dayjs";
import axios from "axios";
import APIMap from "../api_map.js";
import jwt from "jwt-decode";
import { tableDetails } from "../constants_map.js";
import { createScopePermissions } from "../assets/constants/scope";
import getIP from "../getIP.js";

export const AuthContext = React.createContext();
import { useTranslation } from "react-i18next";
export const AuthProvider = ({ children }) => {
  const IP = getIP();
  const SECRETPASS = process.env.REACT_APP_SECRETPASS;
  const [accountsEditData, setAccountsEditData] = useState();
  const [packagesEditData, setPackagesEditData] = useState();
  const [rateSheetEditData, setRateSheetEditData] = useState();

  const { t, i18n } = useTranslation();

  const [state, setState] = useState({
    isLoaded: false,
    open: false,
    terms: "",
    loading: false,
    mobile: "",
    email2: "",
  });
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails")) || null
  );
  const [userRole, setUserRole] = useState("");
  const [enableVoice, setEnableVoice] = useState(false);
  const [providerTab, setProviderTab] = useState("SMS");
  const [routingTab, setRoutingTab] = useState("SMS");
  const [smsTemplates] = useState([]);
  const [emailTemplates] = useState([]);
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("user")) || null
  );
  const [usercredential, setUserCredential] = useState(
    JSON.parse(localStorage.getItem("usercredential")) || null
  );
  const [usermfa, setUserMfa] = useState(
    JSON.parse(localStorage.getItem("usermfa")) || null
  );
  const [email, setEmail] = useState("");
  const [audio, setAudio] = useState(new Audio());
  const [userEmail, setUserEmail] = useState(null);
  const [otp] = useState(0);
  const [smsTemplateDetails, setSmsTemplateDetails] = useState({});
  const [EmailTemplateDetails, setEmailTemplateDetails] = useState({});
  const [ContactDetails, setContactDetails] = useState({});
  const [ContactGroupDetails, setContactGroupDetails] = useState({});
  const [roleTemplates, setTemplates] = useState([]);
  const [distributionListDetails, setdistributionListDetails] = useState({});
  const [filesummary, setfilesummary] = useState({});
  const [resourceData, setResourceData] = useState([]);
  const [singleUserDetails, setSingleUserDetails] = useState({});
  const [editRoleData, setEditRoleData] = useState("");
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userData")) || {
      email: "",
      name: "",
      contactNo: "",
    }
  );
  const [packages, setPackages] = useState([]);
  const [userGroups, setUserGroups] = useState(
    JSON.parse(localStorage.getItem("userGroups")) || []
  );
  const [rateSheets, setRateSheets] = useState([]);
  const [rateSheetDetails, setRateSheetDetails] = useState([]);

  const [numberData, setNumberData] = useState({});
  const [selWaTemplateData, setSelWaTemplateData] = useState([]);
  const [tab, setTab] = useState("SMS");
  const [userScope, setUserScope] = useState({});
  const [vrDetails, setVrDetails] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [beeInstance, setBeeInstance] = useState(undefined);
  const [collapsed, setCollapsed] = useState(false);
  const [globalConfig, setGlobalConfig] = useState([]);
  const [globalConfigMfa, setGlobalConfigMfa] = useState(0);
  const [Time, setResendTime] = useState(0);
  const [allowedChannels, setAllowedChannels] = useState([]);
  const [currencyCode, setCurrencyCode] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const exceptionEndpoints = [];
  axios.interceptors.request.use(
    (config) => {
      const isException = exceptionEndpoints.some((endpoint) =>
        config.url.includes(endpoint)
      );
      if (!isException) {
        config.headers["Accept-Language"] = i18n.language;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const channels = {
    sms: "SMS",
    wa: "WhatsApp",
    voice: "Voice",
    vr: "Virtual Receptionist",
    email: "Email",
    rcs: "RCS",
    chatbot: "Chatbot",
    network: "Network",
    studio: "Studio",
  };

  const token = userDetails?.token;

  useEffect(() => {
    const user = sessionStorage.getItem("user");
    if (user && user != "null") {
      setUser(JSON.parse(user));
    }
    const usermfa = localStorage.getItem("usermfa");
    if (usermfa && usermfa != "null") {
      setUser(JSON.parse(usermfa));
    }
    const usercredential = localStorage.getItem("usercredential");
    if (usercredential && usercredential != "null") {
      setUser(JSON.parse(usercredential));
    }
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      setUserDetails(JSON.parse(userDetails));
    }
    const userData = localStorage.getItem("userData");
    if (userData) {
      setUserData(JSON.parse(userData));
    }
    const userGroups = localStorage.getItem("userGroups");
    if (userGroups) {
      setUserGroups(JSON.parse(userGroups));
    }
  }, []);

  const getAllCountries = () => {
    const config = {
      headers: {
        Authorization: "bearer " + token,
      },
    };
    var url = IP + APIMap("getCountries") + "?paging=0";
    axios.get(url, config).then((res) => {
      setAllCountries(res?.data?.data || []);
    });
  };

  const getGlobalConfig = async () => {
    var url = IP + APIMap("getConfig") + "?scope=gui";
    try {
      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };
      let res = await axios.get(url, config);
      setGlobalConfig(res?.data?.data);
      let allowedChannels = ["All Channels"];
      let dynamicChannels = res?.data?.data
        ?.find((e) => e.key === "allowedChannels")
        .value.trim()
        .split(",")
        .map((e) => e.trim())
        .map((e) => channels[e]);
      allowedChannels = [...allowedChannels, ...dynamicChannels];
      const currencyCode = res?.data?.data?.find(
        (e) => e.key === "currencyCode"
      )?.value;
      const currencySymbol = res?.data?.data?.find(
        (e) => e.key === "currencySymbol"
      )?.value;
      setAllowedChannels(allowedChannels);
      setCurrencyCode(currencyCode);
      setCurrencySymbol(currencySymbol);
    } catch (error) {
      setAllowedChannels(["All Channels", "SMS"]);
      console.log("Error while fetching global config");
    }
  };

  useEffect(() => {
    if (user) {
      sessionStorage.setItem("user", JSON.stringify(user));
    }
    localStorage.setItem("usermfa", JSON.stringify(usermfa));
    localStorage.setItem("usercredential", JSON.stringify(usercredential));
    localStorage.setItem("userDetails", JSON.stringify(userDetails));
    localStorage.setItem("userData", JSON.stringify(userData));
    if (userDetails && userDetails.scope) {
      let data = createScopePermissions(userDetails.scope);
      setUserScope((userScope) => ({ ...userScope, ...data }));
      let role = userDetails.realm_access.roles[0].split("~");
      setUserRole(role[2]);
      getAllCountries();
      getGlobalConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, usercredential, usermfa, userDetails, userData]);

  const clearStorage = () => {
    let language = localStorage.getItem("i18nextLng");
    localStorage.clear();
    setUser(null);
    setUserEmail(null);
    setUserDetails(null);
    setUserData({
      email: "",
      name: "",
      contactNo: "",
    });
    sessionStorage.clear();
    localStorage.setItem("i18nextLng", language);
    localStorage.setItem("logout", dayjs());
  };

  const logout = async (status, revokeAll) => {
    const accessTok = sessionStorage.getItem("access");
    const refreshTok = sessionStorage.getItem("refresh");
    const revokeUrl = IP + APIMap("revoke");

    try {
      let revRes = null;
      if (!status) {
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${accessTok}`,
            },
          };
          revRes = await axios.post(
            revokeUrl,
            {
              token: refreshTok,
            },
            config
          );
        } catch (err) {
          console.log(
            "error while calling access token revoke endpoint: ",
            err
          );
        }
      }

      if (revRes?.status === 200 || status) {
        clearStorage();
      }
    } catch (err) {
      console.error("error while logging out: ", err);
    }
  };

  let refreshing_token = null;
  axios.interceptors.response.use(
    async function (response) {
      return response;
    },
    async function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      //  if refresh token response is 401 then logout
      if (
        error?.config?.url === IP + APIMap("refreshToken") &&
        error?.response?.status === 401
      ) {
        await logout(true);
        return error;
      }

      const prevRequest = error?.config;

      // if access token expired fetch new access token
      if (
        error.config?.url !== IP + APIMap("Cpassloginform") &&
        error.config?.url !== IP + APIMap("loginMfa") &&
        error?.response?.status === 401 &&
        !prevRequest?.sent &&
        !(error?.response?.data?.httpCode === 401) // Invalid api Key for WA Sandbox
      ) {
        prevRequest.sent = true;

        refreshing_token = refreshing_token
          ? refreshing_token
          : refreshTokens();

        const result = await refreshing_token;

        // for calling refresh token api only once
        refreshing_token = null;

        prevRequest.headers[
          "Authorization"
        ] = `Bearer ${result.data.access_token}`;
        return prevRequest;
      }

      return Promise.reject(error);
    }
  );

  const refreshTokens = async () => {
    try {
      const refreshToken = sessionStorage.getItem("refresh");
      let payload = {
        refresh_token: refreshToken,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      };

      var url = IP + APIMap("refreshToken");
      let result = await axios.post(url, payload, config);

      sessionStorage.setItem("refresh", result.data.refresh_token);
      sessionStorage.setItem("access", result.data.access_token);
      console.log("set to session storage");
      let accessToken = jwt(result.data.access_token);
      accessToken["token"] = result.data.access_token.trim();
      setUser({ data: result.data });
      setUserDetails(accessToken);
      setUserGroups(accessToken.groups);
      return result;
    } catch (error) {
      //console.log(error);
    }
  };

  const value = useMemo(() => {
    const signup = async (param) => {
      localStorage.removeItem("count");
      localStorage.removeItem("count3");
      localStorage.removeItem("count1");
      localStorage.removeItem("COUNTER_KEY1");
      localStorage.removeItem("COUNTER_KEY");
      localStorage.removeItem("COUNTER_KEY3");
      setEmail(param.email);

      localStorage.setItem("email", param.email);
      setState({ ...state, loading: true });
      var url = IP + APIMap("signup");
      localStorage.setItem("timerOn", true);
      localStorage.setItem("countryCode", param?.countryCode?.value);
      localStorage.setItem("mobileNumber", param.mobileNumber);
      const result = await axios.post(url, {
        name: param.name,
        email: param.email,
        company: param.company,
        countryCode: param.countryCode?.value,
        mobile: param.mobileNumber,
      });
      return result;
    };
    const EmailNotification = (email, countryCode, mobile) => {
      if (!countryCode) countryCode = localStorage.getItem("countryCode");
      if (!mobile) mobile = localStorage.getItem("mobileNumber");
      localStorage.setItem("timerOn", true);
      localStorage.setItem("email", email);
      if (localStorage.getItem("count") == null) {
        localStorage.setItem("count", 1);
      } else {
        localStorage.setItem(
          "count",
          parseInt(localStorage.getItem("count")) + 1
        );
      }

      var url = IP + APIMap("activationlink");
      const targetarr = [];
      targetarr.push(email);
      return axios.post(url, {
        targets: [email],
        msgTemplate: "setPasswordEmail", // base64 encoded content
        ...(countryCode && mobile
          ? {
              templateValues: {
                COUNTRY_CODE: countryCode,
                MOBILE: mobile,
              },
            }
          : undefined),
      });
    };

    const encryptText = (text) => {
      // const encryptedText = CryptoJS.AES.encrypt(
      //   JSON.stringify(text),
      //   SECRETPASS
      // ).toString();
      // return encryptedText || "";
      return text;
    };

    const setPassword = (param) => {
      var url = IP + APIMap("setpassword");
      localStorage.setItem("userid", param.userid);
      return axios.post(url, {
        token: param.usertoken,
        password: encryptText(param.password),
        uid: param.userid,
      });
    };
    const handleClose = () => {
      setState({ ...state, open: false });
    };

    const setMobileNumber = async (param) => {
      localStorage.setItem("mobileNumber", param.mobileNumber);
      localStorage.setItem("countryCode", param.countryCode.value);
      localStorage.setItem("timerOn1", true);
      localStorage.setItem("otpCounter", 1);

      if (localStorage.getItem("count1") == null) {
        localStorage.setItem("count1", 1);
      } else {
        localStorage.setItem(
          "count1",
          parseInt(localStorage.getItem("count1")) + 1
        );
      }

      var url = IP + APIMap("sendotp");
      let jsonObject = {};
      if (localStorage.getItem("userid")) {
        if (localStorage.getItem("email")) {
          jsonObject.targets = [localStorage.getItem("email")];
        } else {
          jsonObject.targets = [""];
        }

        jsonObject.uid = localStorage.getItem("userid");
        jsonObject.ttl = "5";
      } else {
        jsonObject.targets = [localStorage.getItem("email")];
        jsonObject.ttl = "5";
      }

      return await axios.post(url, jsonObject);
    };
    const getTerms = async () => {
      var url = IP + APIMap("systemDocs");
      url = url.replace("{filename}", "terms_and_conditions.html");
      const result = await axios.get(url);
      return result;
    };
    const getScreenElement = () => {
      var url = IP + APIMap("screenelement");
      return axios.get(url);
    };

    const addSmsTemplate = async (obj) => {
      let TemplateMsgs = [];
      TemplateMsgs.push({ language: "en", msg: obj.message });
      const obj1 = {
        name: obj.name,
        type: obj.type,
        category: obj.category,
        defaultLanguage: "en",
        messages: TemplateMsgs,
      };

      const config = {
        headers: {
          Authorization: `bearer ${userDetails?.token}`,
        },
      };

      var url = IP + APIMap("addTemplate");

      let response = await axios.post(url, obj1, config);
      return response;
    };
    const getSmsTemplates = async (type, pageParam, status, category) => {
      const config = {
        headers: {
          Authorization: `bearer ${userDetails?.token}`,
        },
      };
      let statusString = `${
        status || status === "0" || status === 0 ? `&status=${status}` : ""
      }`;
      let categoryString = `${
        category || category === "0" || category === 0
          ? `&category=${category}`
          : ""
      }`;
      let url = "";
      url = IP + APIMap("getTemplates");
      url =
        url +
        `?type=${type}&pgsize=3&pgindex=${pageParam}${statusString}${categoryString}`;

      let response = await axios.get(url, config);

      return response;
    };
    const deleteSmsTemplate = async (templateId) => {
      const config = {
        headers: {
          Authorization: `bearer ${userDetails?.token}`,
        },
      };

      let url = IP + APIMap("deleteTemplate");
      url = url.replace("{id}", templateId);
      let response = await axios.delete(url, config);
      return response;
    };

    const uploadblockedword = async (obj) => {
      var response;
      var config = {};
      var url = "";
      {
        let payload = new FormData();

        payload.append("file", obj.file);
        config = {
          headers: {
            Authorization: "bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        };
        url = IP + APIMap("uploadblockedword");
        let response = await axios.post(url, payload, config);
      }
      return response;
    };

    const uploadreplacedword = async (obj) => {
      var response;
      var config = {};
      var url = "";
      {
        let payload = new FormData();
        payload.append("file", obj.file);
        config = {
          headers: {
            Authorization: "bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        };
        url = IP + APIMap("uploadreplacedword");

        let response = await axios.post(url, payload, config);
      }
      return response;
    };

    const addEmailTemplate = async (obj) => {
      var response;
      var config = {};
      var url = "";

      if (obj.type === "s" || obj.type === "p") {
        var obj1 = {
          name: obj.name,
          type: obj.type,
          message: obj.message,
        };
        config = {
          headers: {
            Authorization: "bearer " + token,
          },
        };
        url = IP + APIMap("addTemplateEmail");
        response = await axios.post(url, obj1, config);
      } else if (obj.type == "u") {
        let payload = new FormData();
        payload.append("name", obj.name);
        payload.append("type", obj.type);
        payload.append("file", obj.file);
        payload.append("html", obj.html);
        config = {
          headers: {
            Authorization: "bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        };
        url = IP + APIMap("uploadTemplateEmail");

        console.log("Posting ----", url);
        console.log("Config in Auth ", config);
        for (let [key, val] of payload.entries()) {
          console.log(key, val);
        }
        let response = await axios.post(url, payload, config);
        console.log("Response is ", response);
      } else if (obj.type == "r") {
        let payload = new FormData();
        payload.append("name", obj.name);
        payload.append("type", obj.type);
        payload.append("html", obj.html);
        payload.append("json", JSON.stringify(obj.json));
        config = {
          headers: {
            Authorization: "bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        };
        url = IP + APIMap("uploadContentEmail");

        console.log("Posting ----", url);
        console.log("Config in Auth ", config);
        for (let [key, val] of payload.entries()) {
          console.log(key, val);
        }
        let response = await axios.post(url, payload, config);
        console.log("Response is ", response);
      }
      return response;
    };

    const getEmailTemplates = async (type, pageParam) => {
      const config = {
        headers: {
          Authorization: `bearer ${userDetails?.token}`,
        },
      };
      let url = "";
      url = IP + APIMap("getTemplatesEmail");

      url = url + `?type=${type}&pgsize=3&pgindex=${pageParam}`;

      let response = await axios.get(url, config);

      return response;
    };
    const deleteEmailTemplate = async (templateId) => {
      const config = {
        headers: {
          Authorization: `bearer ${userDetails?.token}`,
        },
      };

      let url = IP + APIMap("deleteTemplateEmail");
      url = url.replace("{id}", templateId);
      let response = await axios.delete(url, config);
      return response;
    };
    const getPolicy = () => {
      var url = IP + APIMap("systemDocs");
      url = url.replace("{filename}", "privacy_policy.html");
      return axios.get(url);
    };

    const imageUrlToBase64 = async (url, token) => {
      try {
        const response = await axios.get(url, {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const blob = response.data;

        return new Promise((resolve, reject) => {
          try {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              resolve(reader.result);
            };
          } catch (e) {
            reject(e);
          }
        });
      } catch (error) {
        console.error("There was an error fetching the image:", error);
        throw error;
      }
    };

    const changePassword = (param) => {
      const config = {
        headers: {
          Authorization: `Bearer ${param.token}`,
        },
      };

      var url = IP + APIMap("changePassword");

      //Encrypt passwords

      return axios.post(
        url,
        {
          oldPassword: encryptText(param.oldpassword),
          newPassword: encryptText(param.newpassword),
          token: sessionStorage.getItem("refresh"),
        },
        config
      );
    };
    const getUser = async (uid, token) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      var url = IP + APIMap("getuserid");
      url = url.replace("{id}", uid);

      let resp = await axios.get(url, config);

      // Fetch the image using Axios
      let profilebase64 = "";
      if (resp.data.profilepicture) {
        try {
          const base64Image = await imageUrlToBase64(
            resp.data.profilepicture,
            token
          );

          profilebase64 = base64Image;
        } catch (err) {
          console.log("error fetching profileImg: ", err);
        }
      }

      setUserData({
        ...userData,
        email: resp.data.email,
        name: resp.data.name,
        contactNo: resp.data.contactNo,
        countryCode: resp.data.countryCode,
        profileImg: profilebase64,
        organisation: resp.data.organisation,
      });
    };

    const SendOtp = () => {
      localStorage.setItem("otp", localStorage.setItem("otp") + 1);
    };

    const login = async (param) => {
      localStorage.setItem("email", param.email);
      var url = IP + APIMap("Cpassloginform");
      try {
        let response = await axios.post(url, {
          email: param.email,
          password: encryptText(param.password),
          interface: "gui",
        });
        sessionStorage.setItem("access", response.data.access_token);
        sessionStorage.setItem("refresh", response.data.refresh_token);
        setUser(response);
        let accessToken = jwt(response.data.access_token);
        accessToken["token"] = response.data.access_token.trim();
        getUser(accessToken.sub, accessToken.token);
        setUserGroups(accessToken.groups);
        localStorage.setItem("userGroups", JSON.stringify(accessToken.groups));
        setUserDetails(accessToken);

        return accessToken;
      } catch (err) {
        throw err;
      }
    };

    const loginMfa = async (param) => {
      localStorage.setItem("email", param.email);
      var url = IP + APIMap("loginMfa");
      try {
        let response = await axios.post(url, {
          email: param.email,
          password: encryptText(param.password),
        });
        setUserMfa(response);
        console.log("setusermfa", usermfa);
        setUserCredential(param);
        console.log("usercredential", usercredential);
        return response;
      } catch (err) {
        throw err;
      }
    };

    const sendMfaOtp = async (token, sub) => {
      var sendotpurl = IP + APIMap("sentOTP");
      const body = {
        sid: token,
        uid: sub,
      };

      try {
        const response = await axios.post(sendotpurl, body);
        console.log("success", response);
        return response;
      } catch (err) {
        return err;
      }
    };

    const resendMfaOtp = async (token, sub) => {
      var sendotpurl = IP + APIMap("sentOTP");
      const body = {
        sid: token,
        uid: sub,
      };
      try {
        const response = await axios.post(sendotpurl, body);
        console.log("success", response);
        return response;
      } catch (err) {
        return err;
      }
    };

    const updateUser = async ({ uid, values, token, profileImg }) => {
      let formData = new FormData();
      formData.append("name", values.name);
      formData.append("contactNo", values.mobileNumber);
      formData.append("countryCode", values.countryCode.value);

      if (Object.prototype.hasOwnProperty.call(values, "vrUserSettings")) {
        formData.append("vrUserSettings", values.vrUserSettings);
      }
      if (profileImg !== null) formData.append("profileImg", profileImg);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      var url = IP + APIMap("updateuser");
      url = url.replace("{id}", uid);

      let response = await axios.put(url, formData, config);

      const updatedUserData = {
        ...userData,
        name: values.name,
        contactNo: values.mobileNumber,
        countryCode: values.countryCode.value,
        ...(Object.prototype.hasOwnProperty.call(values, "vrUserSettings") && {
          vrUserSettings: values.vrUserSettings,
        }),
        ...(profileImg && { profileImg: URL.createObjectURL(profileImg) }),
      };
      localStorage.setItem("userData", JSON.stringify(updatedUserData));

      setUserData(updatedUserData);
      return response;
    };

    const setLoader = () => {
      setState({ ...state, loading: false });
    };

    const getTemplateById = async (id) => {
      var url = IP + APIMap("getTemplate");
      url = url.replace("{id}", id);
      let response = await axios.get(url);
      return response;
    };

    const editSmsTemplate = async (obj) => {
      const config = {
        headers: {
          Authorization: `bearer ${userDetails?.token}`,
        },
      };
      let TemplateMsgs = [];
      TemplateMsgs.push({ language: "en", message: obj.message, msg: obj.msg });
      let url = IP + APIMap("editTemplate");
      url = url.replace("{id}", obj.id);
      let response = await axios.put(
        url,
        {
          name: obj.name,
          type: obj.type,
          defaultLanguage: "en",
          category: obj.category,
          description: obj.description,
          messages: TemplateMsgs,
        },
        config
      );

      return await response;
    };

    const editEmailTemplate = async (obj) => {
      const config = {
        headers: {
          Authorization: `bearer ${userDetails?.token}`,
        },
      };

      var response;

      if (obj.type == "s" || obj.type == "p") {
        let url = IP + APIMap("editTemplateEmail");
        url = url.replace("{id}", obj.id);
        response = await axios.put(
          url,
          {
            name: obj.name,
            type: obj.type,
            defaultLanguage: "en",
            description: obj.description,
            message: obj.message,
          },
          config
        );
      } else if (obj.type == "u" || obj.type == "r") {
        let url = IP + APIMap("cloneTemplateEmail");
        console.log("With type as ", obj.type, " and id as ", obj.id);
        url = url.replace("{id}", obj.id);
        console.log("Final Posting URL is ", url);
        let payload = {
          name: obj.name,
          type: obj.type,
          defaultLanguage: "en",
          id: obj.id,
          userId: obj.userId,
          templateURL: obj.templateURL,
          createdAt: obj.createdAt,
        };
        if (obj.type === "r") {
          payload.templateURLJSON = obj.templateURLJSON;
        }
        response = await axios.put(
          url,

          payload,
          config
        );
      }
      return response;
    };
    const editRichMediaEmailTemplate = async (obj) => {
      const config = {
        headers: {
          Authorization: `bearer ${userDetails?.token}`,
        },
      };

      var response;

      let url = IP + APIMap("editTemplateContentEmail");
      url = url.replace("{id}", obj.id);

      response = await axios.put(
        url,
        {
          name: obj.name,
          type: obj.type,
          defaultLanguage: "en",
          id: obj.id,
          userId: obj.userId,
          templateURL: obj.templateURL,
          createdAt: obj.createdAt,
          json: obj.json,
          html: obj.htmlContent,
        },
        config
      );

      return await response;
    };
    const forgotPassword = ({
      email,
      countryCode,
      mobileNumber,
      activationLinkMode,
    }) => {
      const activtnLinkMode = activationLinkMode || "email";
      setState({ ...state, loading: true, email2: email });
      localStorage.setItem("timerOn3", true);
      if (localStorage.getItem("count3") == null) {
        localStorage.setItem("count3", 1);
      } else {
        localStorage.setItem(
          "count3",
          parseInt(localStorage.getItem("count3")) + 1
        );
      }
      localStorage.setItem("email2", email);
      localStorage.setItem("countryCode", countryCode);
      localStorage.setItem("mobileNumber", mobileNumber);
      var url = IP + APIMap("forgotpassword");
      const body = {
        ...(activtnLinkMode === "email" && {
          email: localStorage.getItem("email2"),
        }),
        ...(activtnLinkMode === "sms" && {
          countryCode,
          mobileNumber,
        }),
        ttl: "5",
      };
      return axios.post(url, body);
    };
    const Lang = () => {
      var url = IP + APIMap("systemDocs");
      url = url.replace("{filename}", "lang.json");
      return axios.get(url);
    };

    const Logo = () => {
      var url = IP + APIMap("assetsAccounts");
      url = url.replace("{groupname}", "default");
      url = url.replace("{filename}", "comviva-logo.svg");
      return axios.get(url);
    };
    const cpassLogo = () => {
      var url = IP + APIMap("assetsAccounts");
      url = url.replace("{groupname}", "default");
      url = url.replace("{filename}", "comviva-logo.svg");
      return axios.get(url);
    };
    const Copyright = () => {
      var url = IP + APIMap("systemDocs");
      url = url.replace("{filename}", "copyright.html");
      return axios.get(url);
    };

    const BackGroundImage = () => {
      var url = IP + APIMap("assetsAccounts");
      url = url.replace("{groupname}", "default");
      url = url.replace("{filename}", "comviva-background.jpg");
      return axios.get(url);
    };

    const resetPassword = (token, n, userid) => {
      setState({ ...state, loading: true });
      var url = IP + APIMap("resetpassword");
      return axios.post(url, {
        token: token,
        password: n,
        reset: 1,
        uid: userid,
      });
    };
    const verifyOtp = (n) => {
      setState({ ...state, loading: true });
      var url = IP + APIMap("verifyotp");
      let jsonObject = {};
      jsonObject.countryCode = localStorage.getItem("countryCode");
      jsonObject.contactNo = localStorage.getItem("mobileNumber");
      jsonObject.otp = n;
      if (localStorage.getItem("userid")) {
        jsonObject.issuedTo = localStorage.getItem("userid");
      } else {
        jsonObject.issuedTo = localStorage.getItem("email");
      }
      return axios.post(url, jsonObject);
    };
    const verifyEmail = () => {
      var url = IP + APIMap("emailverification");
      return axios.post(url, {
        email: localStorage.getItem("email"),
        message: "", // base64 encoded content
        template: "", // an email template existing in the system.
        templateValues: {}, // a json object containing keys and values for template placeholder.
        ttl: "2m", // A string consisting of a number and a unit of time. for ex: 48 hours -> 48h
        appInfo: {},
        apppCallback: "",
      });
    };

    //contact management
    const addcontact = async (obj, additionalValues = null) => {
      var obj1 = {
        name: obj.name,
        phone: obj.mobileNumber,
        countryCode: obj.countryCode.value,
        email: obj.email,
        address: obj.address,
        groups: obj.groups,
      };

      if (additionalValues) {
        for (const val in additionalValues) {
          // dropdown values
          if (
            additionalValues?.[val]?.label &&
            additionalValues?.[val]?.value
          ) {
            obj1[val] = additionalValues?.[val]?.value;
          } else {
            obj1[val] = additionalValues?.[val];
          }
        }
      }

      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };

      var url = IP + APIMap("addcontact");
      let response = await axios.post(url, obj1, config);
      return response;
    };
    const addbulkcontact = async (obj) => {
      var obj1 = {
        jobId: obj.jobId,
      };

      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };
      var url = IP + APIMap("addbulkcontact");

      let response = await axios.post(url, obj1, config);
      return response;
    };
    const FileUpload = async (obj) => {
      let formData = new FormData();
      formData.append("file", obj);

      const config = {
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      };
      var url = IP + APIMap("bulkfileupload");

      let response = await axios.post(url, formData, config);
      return response;
    };

    const RateSheetFileUpload = async (obj) => {
      let formData = new FormData();
      formData.append("file", obj);

      const config = {
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      };

      var url = IP + APIMap("bulkfileupload");

      let response = await axios.post(url, formData, config);
      return response;
    };

    const DLFileUpload = async (obj) => {
      var config = {};
      var response = {};
      var url = IP + APIMap("dlfileuplaod");

      if (obj.type === "file") {
        let formData = new FormData();
        formData.append("file", obj.file);

        config = {
          headers: {
            Authorization: "bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        };
        response = await axios.post(url, formData, config);
      } else if (obj.type === "manual") {
        var obj1 = {
          manualLines: obj.manualLines,
          uploadFileToken: 0,
        };

        config = {
          headers: {
            Authorization: "bearer " + token,
          },
        };
        response = await axios.post(url, obj1, config);
      }

      return response;
    };
    const DLFileUploadEmail = async (obj) => {
      var config = {};

      var response = {};
      var url = IP + APIMap("emaildlfileuplaod");

      if (obj.type === "file") {
        let formData = new FormData();
        formData.append("file", obj.file);

        config = {
          headers: {
            Authorization: "bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        };
        response = await axios.post(url, formData, config);
      } else if (obj.type === "manual") {
        var obj1 = {
          manualLines: obj.manualLines,
          uploadFileToken: 0,
        };

        config = {
          headers: {
            Authorization: "bearer " + token,
          },
        };
        response = await axios.post(url, obj1, config);
      }

      return response;
    };

    const editDLFileUpload = async (obj, dl) => {
      var config = {};
      var response = {};
      var url = IP + APIMap("editdlfileupload");
      url = url.replace("{id}", dl.jobId);
      if (obj.type === "file") {
        let formData = new FormData();
        formData.append("file", obj.file);

        config = {
          headers: {
            Authorization: "bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        };
        response = await axios.put(url, formData, config);
      } else if (obj.type === "manual") {
        var obj1 = {
          manualLines: obj.manualLines,
          uploadFileToken: 0,
        };

        config = {
          headers: {
            Authorization: "bearer " + token,
          },
        };
        response = await axios.put(url, obj1, config);
      }
      return response;
    };
    const editEmailDLFileUpload = async (obj, dl) => {
      var config = {};

      var response = {};
      var url = IP + APIMap("editEmailDLFileUpload");
      url = url.replace("{id}", dl.jobId);
      if (obj.type === "file") {
        let formData = new FormData();
        formData.append("file", obj.file);

        config = {
          headers: {
            Authorization: "bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        };
        response = await axios.put(url, formData, config);
      } else if (obj.type === "manual") {
        var obj1 = {
          manualLines: obj.manualLines,
          uploadFileToken: 0,
        };

        config = {
          headers: {
            Authorization: "bearer " + token,
          },
        };
        response = await axios.put(url, obj1, config);
      }

      return response;
    };
    const deletefile = async (jobId, fileNames) => {
      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
        data: {
          files: Object.keys(fileNames),
          jobId: jobId,
        },
      };
      var url = IP + APIMap("removefileupload");

      try {
        let response = await axios.delete(url, config);
        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    };

    const getsummary = async (obj) => {
      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };

      var url = IP;
      if (obj.type === "Email") {
        url = url + APIMap("getsummary");
      } else {
        url = url + APIMap("getsummary");
      }

      url = url.replace("{id}", obj.jobId);

      let response = await axios.get(url, config);

      return await response;
    };

    const ContactList = async ({ queryKey, pageParam = 0 }) => {
      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };

      const searchString =
        queryKey[1] || queryKey[1] === 0 || queryKey[1] === "0"
          ? `&name=${queryKey[1]}`
          : "";

      var baseUrl = IP + APIMap("contactlist");

      var url =
        baseUrl +
        `?pgindex=${pageParam}&pgsize=${tableDetails.pageSize}&contact=true${searchString}`;

      let response = await axios.get(url, config);

      return { response, nextPage: pageParam + 1 };
    };

    const ContactListForExport = async ({ queryKey }) => {
      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };

      const searchString =
        queryKey[1] || queryKey[1] === 0 || queryKey[1] === "0"
          ? `&name=${queryKey[1]}`
          : "";

      var baseUrl = IP + APIMap("contactlist");
      var url =
        baseUrl + `?page=${1}&per_page=1000000&contact=true${searchString}`;

      let response = await axios.get(url, config);
      return response;
    };

    const contactSearchApi = async ({ queryKey, pageParam = 1 }) => {
      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };

      var url = IP + APIMap("contactlist");
      url =
        url +
        `?page=${pageParam}&per_page=${tableDetails.pageSize}&contact=true&name=${queryKey[1]}`;

      let response = await axios.get(url, config);

      return { response, nextPage: pageParam + 1 };
    };

    const editContactList = async (obj, additionalValues = null) => {
      var addmems = [];
      var delmems = [];
      let grpid = [];
      let objgrp = [];

      objgrp = obj?.groups?.split(",");
      if (ContactDetails.groups !== null && ContactDetails.groups !== "")
        grpid = ContactDetails?.groups?.split(",") || [];
      objgrp.forEach((e) => {
        if (grpid.includes(e) === false) addmems.push(e);
      });
      grpid.forEach((e) => {
        if (objgrp.includes(e) === false) delmems.push(e);
      });

      addmems = addmems.filter((e) => e !== "");
      delmems = delmems.filter((e) => e !== "");

      var obj1 = {
        name: obj.name,
        phone: obj.mobileNumber,
        email: obj.email,
        address: obj.address,
        add: addmems,
        countryCode: obj.countryCode.value,
        delete: delmems,
      };

      if (additionalValues) {
        for (const val in additionalValues) {
          // dropdown values
          if (
            additionalValues?.[val]?.label &&
            additionalValues?.[val]?.value
          ) {
            obj1[val] = additionalValues?.[val]?.value;
          } else {
            obj1[val] = additionalValues?.[val];
          }
        }
      }

      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };
      var url = IP + APIMap("editcontact");

      url = url.replace("{id}", obj.id);

      let response = await axios.put(url, obj1, config);
      return response;
    };

    const deleteContactList = async (param) => {
      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };
      var url = IP + APIMap("deletecontact");

      url = url.replace("{id}", param.queryKey[1]);

      let response = await axios.delete(url, config);
      return response;
    };

    //contactgroup management
    const addcontactgrp = async (obj) => {
      var obj1 = {
        groupName: obj.name,
        ids: obj.id,
      };

      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };

      var url = IP + APIMap("addcontactgrp");

      let response = await axios.post(url, obj1, config);
      return response;
    };

    const Listcontactgrp = async () => {
      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };

      var url = IP + APIMap("contactgrouplist");

      let response = await axios.get(url, config);

      return response;
    };

    const deletecontactgrp = async (param) => {
      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };

      var url = IP + APIMap("deletecontactgrp");

      url = url.replace("{groupname}", param.queryKey[1]);

      let response = await axios.delete(url, config);
      return response;
    };

    const editcontactgrp = async (obj) => {
      var addmems = [];
      var delmems = [];
      let grpid = [];
      grpid = ContactGroupDetails.members.split(",");

      obj.id.forEach((e) => {
        if (grpid.includes(e) === false) addmems.push(e);
      });
      grpid.forEach((e) => {
        if (obj.id.includes(e) === false) delmems.push(e);
      });

      var obj1 = {
        addMems: addmems,
        delMems: delmems,
      };

      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };
      var url = IP + APIMap("updatecontactgrp");

      url = url.replace("{groupname}", obj.name);

      let response = await axios.put(url, obj1, config);
      return response;
    };

    const getdistributionlists = async () => {
      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };
      var url = IP + APIMap("getdistlist");

      let response = await axios.get(url, config);
      return response;
    };

    const getdistributionlistsSMS = async () => {
      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };
      var url = IP + APIMap("getdistlist") + `?channel=sms`;

      let response = await axios.get(url, config);
      return response;
    };

    const getdistributionlistsEmail = async () => {
      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };
      //var url =IP +`/role_mgmt/accounts/${userDetails?.groups[0]}/getentroles?pgsize=${size}&pgindex=${index}`;
      var url = IP + APIMap("getdistlistEmail") + `?channel=email`;
      // var url = "http://localhost:6009/" + APIMap("getdistlistEmail");

      let response = await axios.get(url, config);
      ////console.log("authcontext", response);
      return response;
    };

    const deletedistibutionlist = async (param) => {
      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };
      //var url = "http://43.204.246.49:8081/createcontact"
      var url = IP + APIMap("deletedistlist");
      // var url = "http://localhost:6009/" + APIMap("deletedistlistEmail");

      url = url.replace("{id}", param.queryKey[1]);
      ////console.log("deletecontactgrpurl", url, param);
      let response = await axios.delete(url, config);
      return response;
    };
    const deletedistibutionlistEmail = async (param) => {
      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };
      //var url = "http://43.204.246.49:8081/createcontact"
      var url = IP + APIMap("deletedistlist");
      // var url = "http://localhost:6009/" + APIMap("deletedistlistEmail");

      url = url.replace("{id}", param.queryKey[1]);
      ////console.log("deletecontactgrpurl", url, param);
      let response = await axios.delete(url, config);
      return response;
    };

    const editdistributionlist = async (obj, distlistdetail, values) => {
      var obj1 = {
        jobId: distlistdetail.jobId ? distlistdetail.jobId : null,
        count: obj?.totalUnique ? obj.totalUnique : null,
        desc: values.description,
      };

      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };
      //var url = "http://43.204.246.49:8081/createcontact"
      var url = IP + APIMap("editdistlist");

      url = url.replace("{id}", distlistdetail.listId);
      ////console.log("updateurl", url);
      let response = await axios.put(url, obj1, config);
      return response;

      // return await response;
    };
    const editdistributionlistEmail = async (obj, distlistdetail, values) => {
      var obj1 = {
        jobId: distlistdetail.jobId ? distlistdetail.jobId : null,
        count: obj?.totalUnique ? obj.totalUnique : null,
        desc: values.description,
      };

      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };
      //var url = "http://43.204.246.49:8081/createcontact"
      var url = IP + APIMap("editdistlistEmail");

      url = url.replace("{id}", distlistdetail.listId);
      ////console.log("updateurl", url);
      let response = await axios.put(url, obj1, config);
      return response;

      // return await response;
    };

    const createdistlist = async (jobId, filesummary, values) => {
      console.log("values", values);
      console.log("filesummary", filesummary);
      var obj1 = {
        name: values.name,
        count: filesummary.totalUnique,
        jobId: jobId,
        dlSummary: true,
        status: "A",
        desc: values.description,
        channel: filesummary.channel,
      };

      ////console.log("in authcontext createdlist", obj1);
      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };
      //var url = "http://43.204.246.49:8081/createcontact"
      var url = IP + APIMap("createdistlist");

      let response = await axios.post(url, obj1, config);
      return response;
    };

    const createdistlistEmail = async (jobId, filesummary, values) => {
      var obj1 = {
        name: values.name,
        count: filesummary.totalUnique,
        jobId: jobId,
        dlSummary: true,
        status: "A",
        desc: values.description,
      };

      ////console.log("in authcontext createdlist", obj1);
      const config = {
        headers: {
          Authorization: "bearer " + token,
        },
      };
      //var url = "http://43.204.246.49:8081/createcontact"
      var url = IP + APIMap("createdistlistEmail");
      // var url = "http://localhost:6009/" + APIMap("createdistlistEmail");

      let response = await axios.post(url, obj1, config);
      return response;
    };

    //Role Manangement
    const getTemplates = async () => {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      var url = IP + APIMap("getResourcesTemplates");
      let response = await axios.get(url, config);
      return response;
    };

    const deleteRoleByName = async (param) => {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      var url = IP + APIMap("deleteRoleById");
      url = url.replace("{roleId}", param.queryKey[1]);
      let response = await axios.delete(url, config);
      return response;
    };
    const getResourcesByTemplate = async (param) => {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      var url = IP + APIMap("getResourcesByTemplate");
      url = url.replace("{templateName}", param.queryKey[1]);
      let response = await axios.get(url, config);
      return response;
    };
    const addRole = async (param) => {
      let obj = JSON.parse(JSON.stringify(param));
      let arr = obj.grants.filter((el) => el.check === true);
      arr.forEach((el) => {
        delete el.check;
      });
      obj.grants = arr;

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      var url = IP + APIMap("addRole");
      let response = await axios.post(url, obj, config);
      return response;
    };
    const updateRole = async (param) => {
      let obj = JSON.parse(JSON.stringify(param.obj));
      delete obj.template;
      delete obj.roleName;
      let arr = obj.grants.filter((el) => el.check === true);
      arr.forEach((el) => {
        delete el.check;

        if (Object.keys(el).includes("resourcedisName")) {
          delete el.resourcedisName;
        }
      });
      obj.grants = arr;

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      var url = IP + APIMap("updateRoleById");
      url = url.replace("{roleId}", param.editRoleData);
      let response = await axios.put(url, obj, config);
      return response;
    };

    const rolePaginationApi = async ({ queryKey, pageParam = 1 }) => {
      const config = {
        headers: {
          Authorization: "Bearer " + queryKey[1],
        },
      };
      const startDate =
        queryKey[2] && queryKey[3] ? `&fromdate=${queryKey[2]}` : "";
      const endDate =
        queryKey[2] && queryKey[3] ? `&todate=${queryKey[3]}` : "";
      const filterType = startDate && endDate ? "&filtertype=custom" : "";
      const search = queryKey[4] ? `&name=${queryKey[4]}` : "";
      var url = IP + APIMap("getRoles");
      url =
        url +
        `?pgsize=${tableDetails.pageSize}&pgindex=${pageParam}${startDate}${endDate}${filterType}${search}`;
      let response = await axios.get(url, config);

      return { response, nextPage: pageParam + 1 };
    };

    const getRoleById = async (param) => {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      var url = IP + APIMap("getRoleById");
      url = url.replace("{roleId}", param.queryKey[1]);
      let response = await axios.get(url, config);
      return response;
    };

    const roleForExport = async ({ queryKey }) => {
      const config = {
        headers: {
          Authorization: "Bearer " + queryKey[1],
        },
      };
      const startDate = queryKey[2] ? `&fromdate=${queryKey[2]}` : "";
      const endDate = queryKey[3] ? `&todate=${queryKey[3]}` : "";
      const filterType = startDate && endDate ? "&filtertype=custom" : "";
      const search = queryKey[4] ? `&name=${queryKey[4]}` : "";
      var url = IP + APIMap("getRoles");
      url = url + `?paginate=0${startDate}${endDate}${filterType}${search}`;
      let response = await axios.get(url, config);
      return response;
    };

    const roleSearchApi = async ({ queryKey, pageParam = 1 }) => {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      var url = IP + APIMap("getRoles");
      url =
        url +
        `?name=${queryKey[1]}&pgsize=${tableDetails.pageSize}&pgindex=${pageParam}`;
      let response = await axios.get(url, config);

      return { response, nextPage: pageParam + 1 };
    };
    const getRoleByGroup = async () => {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      var url = IP + APIMap("getRoles");
      let response = await axios.get(url, config);

      return response;
    };

    const getRoleResource = async () => {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      var url = IP + APIMap("getRoleResource");
      let response = await axios.get(url, config);
      setResourceData(response.data);
      return response;
    };
    const getDateFilter = async ({ queryKey, pageParam = 1 }) => {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      var url = "";
      if (queryKey[3] !== "custom") {
        url = IP + APIMap("getRoles");
        url =
          url +
          `?filtertype=${queryKey[3]}&pgsize=${tableDetails.pageSize}&pgindex=${pageParam}`;
      } else {
        url = IP + APIMap("getRoles");
        url =
          url +
          `?filtertype=${queryKey[3]}&fromdate=${queryKey[1]}&todate=${queryKey[2]}&pgsize=${tableDetails.pageSize}&pgindex=${pageParam}`;
      }
      let response = await axios.get(url, config);
      return {
        response,
        nextPage: pageParam + 1,
      };
    };

    const userStatus = async (email) => {
      var url = IP + APIMap("verifyUserStatus");
      var obj = { user: email };

      let response = await axios.post(url, obj);
      return response;
    };

    const OnBoardingApis = {
      signup,
      EmailNotification,
      setPassword,
      forgotPassword,
      getScreenElement,
      getPolicy,
      getTerms,
      setMobileNumber,
      verifyOtp,
      setLoader,
      resetPassword,
      login,
      loginMfa,
      sendMfaOtp,
      resendMfaOtp,
    };
    return {
      user,
      usercredential,
      usermfa,
      userEmail,
      email,
      Time,
      setUser,
      signup,
      setPassword,
      otp,
      login,
      sendMfaOtp,
      resendMfaOtp,
      logout,
      clearStorage,
      deletefile,
      addEmailTemplate,
      forgotPassword,
      OnBoardingApis,
      resetPassword,
      verifyEmail,
      SendOtp,
      getPolicy,
      EmailNotification,
      getTerms,
      getScreenElement,
      getResourcesByTemplate,
      Lang,
      BackGroundImage,
      Logo,
      Copyright,
      cpassLogo,
      verifyOtp,
      handleClose,
      getsummary,
      setLoader,
      getRoleById,
      smsTemplates,
      emailTemplates,
      addSmsTemplate,
      getSmsTemplates,
      getEmailTemplates,
      deleteSmsTemplate,
      addEmailTemplate,
      getEmailTemplates,
      deleteSmsTemplate,
      deleteEmailTemplate,
      getTemplateById,
      getRoleByGroup,
      smsTemplateDetails,
      setSmsTemplateDetails,
      EmailTemplateDetails,
      setEmailTemplateDetails,
      setContactDetails,
      filesummary,
      setfilesummary,
      userStatus,
      ContactDetails,
      ContactGroupDetails,
      distributionListDetails,
      setdistributionListDetails,
      setContactGroupDetails,
      singleUserDetails,
      setSingleUserDetails,
      getRoleResource,
      roleSearchApi,
      roleForExport,
      updateRole,
      editSmsTemplate,
      editEmailTemplate,
      editContactList,
      changePassword,
      updateUser,
      deleteRoleByName,
      deleteContactList,
      deletecontactgrp,
      rolePaginationApi,
      editRoleData,
      userData,
      setResourceData,
      getTemplates,
      state,
      getUser,
      addRole,
      resourceData,
      getDateFilter,
      setEditRoleData,
      setAccountsEditData,
      accountsEditData,
      setPackagesEditData,
      packagesEditData,
      setRateSheetEditData,
      rateSheetEditData,
      userDetails,
      addcontact,
      addbulkcontact,
      FileUpload,
      RateSheetFileUpload,
      DLFileUpload,
      DLFileUploadEmail,
      editDLFileUpload,
      editEmailDLFileUpload,
      addcontactgrp,
      ContactList,
      ContactListForExport,
      contactSearchApi,
      Listcontactgrp,
      editcontactgrp,
      deletedistibutionlist,
      deletedistibutionlistEmail,
      createdistlist,
      createdistlistEmail,
      uploadblockedword,
      uploadreplacedword,
      getdistributionlists,
      getdistributionlistsSMS,
      getdistributionlistsEmail,
      editdistributionlist,
      editdistributionlistEmail,
      setTemplates,
      roleTemplates,
      showSessionExpiredModal,
      setShowSessionExpiredModal,
      packages,
      setPackages,
      rateSheets,
      setRateSheets,
      rateSheetDetails,
      setRateSheetDetails,
      userScope,
      userRole,
      userGroups,
      selWaTemplateData,
      setSelWaTemplateData,
      enableVoice,
      setEnableVoice,
      setProviderTab,
      setRoutingTab,
      routingTab,
      providerTab,
      numberData,
      setNumberData,
      vrDetails,
      setVrDetails,
      allCountries,
      getAllCountries,
      beeInstance,
      collapsed,
      setCollapsed,
      addEmailTemplate,
      editRichMediaEmailTemplate,
      getGlobalConfig,
      globalConfig,
      globalConfigMfa,
      currencyCode,
      currencySymbol,
      allowedChannels,
      audio,
      setAudio,
      tab,
      setTab,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    user,
    usercredential,
    usermfa,
    state,
    userEmail,
    accountsEditData,
    packagesEditData,
    rateSheetEditData,
    userData,
    editRoleData,
    smsTemplateDetails,
    EmailTemplateDetails,
    ContactDetails,
    ContactGroupDetails,
    distributionListDetails,
    singleUserDetails,
    filesummary,
    resourceData,
    userDetails,
    smsTemplates,
    emailTemplates,
    email,
    IP,
    roleTemplates,
    otp,
    showSessionExpiredModal,
    packages,
    rateSheets,
    rateSheetDetails,
    userScope,
    userRole,
    userGroups,
    selWaTemplateData,
    setSelWaTemplateData,
    enableVoice,
    providerTab,
    routingTab,
    numberData,
    vrDetails,
    allCountries,
    beeInstance,
    collapsed,
    globalConfig,
    globalConfigMfa,
    audio,
    tab,
  ]);
  // if (!isLoaded) {
  //   return <ThemedSuspense />;
  // }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
