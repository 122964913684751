/* eslint-disable no-unused-vars */
import rightarrow from "../../assets/img/right-arrow-grey.svg";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { getIconMapping } from "../../constants_map";
import walletSrc from "../../assets/img/wallet.png";
import smsSrc from "../../icons/smsIcon.svg";
import whatsappSrc from "../../icons/whatsappIcon_.svg";
import numberSrc from "../../icons/numbersIcon.svg";
import billingSrc from "../../icons/billingIcon.svg";
import { t } from "i18next";

function NotificationDropdown(props) {
  const navigate = useNavigate();
  const { userDetails } = useContext(AuthContext);
  //const { mutate: updateAPI } = useMutation(updateNotificationStatus);
  const handleNavigate = (eleId, eleStatus) => {
    //props.unreadRefetch();

    navigate("/app/notification", { state: { id: eleId, status: eleStatus } });
  };

  return (
    <div
      id="toolTip7"
      className="absolute ltr:left-10 rtl:right-10  ltr:-translate-x-64 rtl:translate-x-64  top-9 rounded-xl  table bg-white justify-center items-center shadow-sm  border w-[330px]"
    >
      <div className="divide-y divide-gray-200 border border-white dark:border-gray-800 ">
        {props?.data?.map((ele, i) => {
          return i < 3 ? (
            <div key={i}>
              {/* <a
                href="#"
                className="flex hover:bg-gray-100 px-4 py-2"
                onClick={() => handleNavigate(ele.id)}
              > */}
              <div
                className="flex flex-row justify-between px-4 py-2 hover:bg-AliceBlue"
                onClick={() => handleNavigate(ele.id, ele.read)}
              >
                <div className="flex my-auto rounded-full">
                  <img
                    className="w-7 h-7 text-white"
                    src={
                      getIconMapping(ele.from) === "SMS"
                        ? smsSrc
                        : getIconMapping(ele.from) === "wallet"
                        ? walletSrc
                        : getIconMapping(ele.from) === "Whatsapp"
                        ? whatsappSrc
                        : getIconMapping(ele.from) === "numbers"
                        ? numberSrc
                        : billingSrc
                    }
                    alt={ele.from}
                  ></img>
                </div>
                <div className="pl-2 mt-2 mb-2 w-3/4 font-semibold">
                  <div
                    className={`text-[#394054] text-sm dark:text-gray-400 ${
                      ele.read ? "font-normal" : "font-bold"
                    }`}
                  >
                    {ele.description}
                  </div>
                  <div
                    className={`text-xs text-Dark-Grey ${
                      ele.read ? "font-normal" : "font-bold"
                    }`}
                  >
                    {dayjs(new Date()).diff(dayjs(ele.createdAt), "minutes") >
                    60
                      ? dayjs(new Date()).diff(dayjs(ele.createdAt), "hours") >
                        48
                        ? dayjs(new Date()).diff(dayjs(ele.createdAt), "days") +
                          " days ago"
                        : dayjs(new Date()).diff(
                            dayjs(ele.createdAt),
                            "hours"
                          ) + " hours ago "
                      : dayjs(new Date()).diff(
                          dayjs(ele.createdAt),
                          "minutes"
                        ) + " minutes ago"}
                  </div>
                </div>
                <span>
                  <img
                    alt="reports"
                    className="mx-1 fill-Dark-Grey mt-10"
                    src={rightarrow}
                  ></img>
                </span>
              </div>
              {/* </a> */}
            </div>
          ) : null;
        })}

        <div
          className="text-center  py-5 text-sm  font-semibold  border-gray-100"
          onClick={() => {
            props.notificationRefetch();
            props.unreadRefetch();
            props.navigate("/app/notification");
          }}
        >
          <span className="text-Dark-Grey underline">
            {t("_View_all_notifications")}
          </span>
        </div>
      </div>
    </div>
  );
}

export default NotificationDropdown;
