import APIMap from "../api_map.js";
import axios from "axios";
import getIP from "../getIP.js";
const IP = getIP();

export async function getAllPaymentProviders({ queryKey }) {
  const config = {
    headers: {
      Authorization: `Bearer ${queryKey[1]}`,
    },
  };

  let url = IP + APIMap("getAllPaymentProviders");

  let response = await axios.get(url, config);
  return response;
}

export async function getPaymentProviderById(options) {
  var url = IP + APIMap("getPaymentProviderById");
  url = url.replace("{id}", options.id);
  const config = {
    headers: {
      Authorization: `Bearer ${options.userDetails.token}`,
    },
  };
  let response = await axios.post(url, options.payload, config);
  return response;
}

export async function requestPayment(url, token, payload) {
  url = IP + url;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let response = await axios.post(url, payload, config);
  return response;
}

export async function checkPaymentStatus(token, txnId) {
  let url = IP + APIMap("checkPaymentStatus");
  url = url.replace("{txnId}", txnId);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let response = await axios.get(url, config);
  return response;
}

export async function fetchPaymentStatus(url, token, txnId) {
  url = IP + url;
  url = url.replace("{transactionId}", txnId);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let response = await axios.get(url, config);
  return response;
}

export async function registrationAPIForEAnd(token, payload) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const url = IP + APIMap("registrationForEAnd");

  let response = await axios.post(url, payload, config);
  return response;
}

export async function finalizationAPIforEAnd(txnId, token) {
  const url = IP + APIMap("finalizationForEAnd");

  let config = null;
  if (token) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }

  const body = {
    txnId: txnId,
  };

  let response = null;
  if (token) {
    response = await axios.post(url, body, config);
  } else {
    response = await axios.post(url, body);
  }

  return response;
}
