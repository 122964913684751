import { useContext } from "react";
import { Navigate } from "react-router-dom";
import Layout from "../../containers/Layout";
// import HeaderLayout from "../../containers/headerLayout";
import { AuthContext } from "../../context/AuthContext";

export default function ProtectedRoute() {
  let { user } = useContext(AuthContext);

  return <>{user !== null ? <Layout /> : <Navigate to={"/auth"} />}</>;
}
