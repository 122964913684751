import { Buffer } from "buffer";
export const decodingJWT = (token) => {
  if (token !== null || token !== undefined) {
    try {
      const base64String = token?.split(".")[1];
      const decodedValue = JSON.parse(
        Buffer.from(base64String, "base64").toString("ascii")
      );
      return decodedValue;
    } catch (err) {
      return "";
    }
  }
  return null;
};
