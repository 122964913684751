import React, { useContext } from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import { ReactComponent as ArrowupIcon } from "../../icons/arrowupicon.svg";
import { ReactComponent as DropdownIcon } from "../../icons/dropdownn.svg";
import * as Icons from "../../icons";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/AuthContext";
import { useTheme } from "../../context/ThemeContext";

function Icon({ icon, ...props }) {
  const Icon = Icons[icon];
  return Icon ? <Icon {...props} /> : <div className="px-6">I</div>;
}

const MENU = ({ route }) => {
  const [subRoute, setSubRoute] = React.useState(false);
  const [subRouteIndex, setSubRouteIndex] = React.useState(0);
  const { collapsed } = useContext(AuthContext);
  const { t } = useTranslation();
  const { theme } = useTheme();
  const showSubRoutes = () => {
    setSubRoute(!subRoute);
  };

  const hexToRgb = (hex) => {
    // Remove the leading '#' if present
    hex = hex?.replace(/^#/, "");

    // Parse the hex value into its RGB components
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return `${r}, ${g}, ${b}`;
  };
  return (
    <div
      className={`flex flex-col ${subRoute ? `${theme.sideMenuColor}` : null}`}
    >
      <div
        className={`flex-row cursor-pointer `}
        style={{ color: theme.menuTextColor }}
        onClick={route.subRoutes && showSubRoutes}
      >
        <NavLink
          onClick={() => {
            setSubRouteIndex(0);
          }}
          to={
            route?.subRoutes && route?.subRoutes?.length > 0
              ? route.subRoutes[subRouteIndex].path
              : route.path
          }
          style={({ isActive }) =>
            isActive
              ? {
                  color: theme.menuTextColorOnHover,
                  background: `${
                    !route.subRoutes
                      ? `rgba(${hexToRgb(theme?.menuTextColorOnHover)}, 0.05)`
                      : null
                  }`,
                }
              : { color: theme.menuTextColor }
          }
          className="flex py-[10px]"
        >
          <Icon
            className="ml-[21px] mr-[15px] h-[18px] w-[17px]"
            icon={route.icon}
          />

          {!collapsed && (
            <>
              <h1 className="text-xs mt-0.5 text-wrap w-28 cursor-pointer">
                {t(`${route.name}`)}
              </h1>

              {route.subRoutes && subRoute ? (
                <div className="flex flex-row-reverse flex-grow self-center mx-5">
                  <ArrowupIcon className="w-[10px] h-[6px]" />
                </div>
              ) : route.subRoutes ? (
                <div className="flex flex-row-reverse flex-grow self-center mx-5">
                  <DropdownIcon className="w-[10px] h-[6px]" />
                </div>
              ) : null}
            </>
          )}
        </NavLink>
      </div>

      {route?.subRoutes &&
      subRoute &&
      route?.subRoutes?.length > 0 &&
      !collapsed
        ? route.subRoutes.map((subRoute, i) => {
            // route.path+subRoute.path
            return (
              <div key={`${subRoute.name}${i}`} className="mb-[5px]">
                <NavLink
                  onClick={() => setSubRouteIndex(i)}
                  to={subRoute.path}
                  key={`${subRoute.name}${i}`}
                  style={({ isActive }) =>
                    isActive
                      ? { color: theme.menuTextColorOnHover }
                      : { color: theme.menuTextColor }
                  }
                >
                  <h1
                    className={`text-xs ltr:ml-[52px] rtl:mr-[52px] cursor-pointer ${
                      subRoute ? "inline-block" : "hidden"
                    }`}
                  >
                    {t(`${subRoute.name}`)}
                  </h1>
                </NavLink>
                <Routes>
                  <Route path={subRoute.path} exact={route.exact} />
                </Routes>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default MENU;
