import { t } from "i18next";
import error from "../../assets/img/error.svg";
import { useTheme } from "../../context/ThemeContext";
const ErrorModal = (props) => {
  const styles = {
    formStyle:
      "grid grid-col border p-10 rounded-lg place-self-center bg-white max-h-[319px] min-w-[400px] max-w-[417px] w-fit place-items-center",
  };
  const { theme } = useTheme();
  return (
    <>
      {props.errorModal && (
        <div className="modal fade fixed z-10 w-screen inset-0 bg-black bg-opacity-40 h-screen flex place-items-center justify-center shadow-md outline-none">
          <div className={styles.formStyle}>
            <div>
              <img src={error} className="w-12 h-12" alt="error" />
            </div>
            <div className="font-bold text-xl mt-5">
              {props.isWarning ? t("_Warning") : t("_Failed")}
            </div>
            <div className="text-base break-words mt-3 text-[#7B7B7B] font-semibold text-center">
              {props.deleteError
                ? props.deleteError
                : t("_Sorry!_Something_went_wrong._Please_try_again")}
            </div>
            {!props.hideShowAgain && (
              <div>
                <button
                  style={{ backgroundColor: theme.primaryColor }}
                  className=" rounded-full font-bold text-sm px-10 py-3 text-white mt-6"
                  onClick={() => {
                    props.setErrorModal(false);
                  }}
                >
                  {props.buttonTxt || t("_TRY_AGAIN")}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ErrorModal;
