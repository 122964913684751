const scopes = {
  rateSheet: "ratesheet",
  wallets: "wltmgmt",
  packages: "pckg",
  senderAddress: "snd_adr",
  senderAddressSpec: "snd_adr_spec",
  shortCodes: "shortcodes",
  accounts: "acmgmt",
  workflow: "wrkfl",
  invoice: "inv",
  users: "usr",
  roles: "rlc",
  campaignManagement: "cmpgmgmt",
  smsTemplates: "smstmp",
  emailTemplates: "emailtmp",
  contactManagement: "cntmgmt",
  profile: "profile",
  email: "email",
  revenueReport: "rvnrpt",
  pushAndPullReport: "pprpt",
  Inventory: "invt",
  assignNumbers: "anum",
  moService: "mosvc",
  numberList: "numls",
  activityLog: "alog",
  enterpriseLog: "enterpriseLog",
  debuglog: "debugLog",
  configureNumbers: "cfgnum",
  groupManagement: "grpmgmt",
  configureIvr: "cfgivr",
  sipManagement: "sipmgmt",
  segment: "segment",
  providers: "providers",
  routing: "routing",
  blacklist: "blacklist",
  dnd: "dnd",
  alerts: "alert",
  emailConfiguration: "email_cfg",
};

const permissions = {
  create: "c",
  read: "r",
  transfer: "t",
  edit: "e",
  delete: "d",
  buy: "b",
  download: "dw",
  approve: "a",
  reject: "rj",
};

const npermissions = {
  c: "create",
  r: "read",
  t: "transfer",
  e: "edit",
  d: "delete",
  b: "buy",
  dw: "download",
  a: "approve",
  rj: "reject",
};

const nscopes = {
  rsheet: "rateSheet",
  wltmgmt: "wallets",
  pckg: "packages",
  snd_adr: "senderAddress",
  snd_adr_spec: "senderAddressSpec",
  //sndrid: "senderId",
  shortcodes: "shortCodes",
  acmgmt: "accounts",
  wrkfl: "workflow",
  inv: "invoice",
  usr: "users",
  rlc: "roles",
  sms_cmpg: "campaignManagement",
  smstmp: "smsTemplates",
  emailtmp: "emailTemplates",
  cntmgmt: "contactManagement",
  profile: "profile",
  email: "email",
  invt: "Inventory",
  anum: "assignNumbers",
  mosvc: "moService",
  numls: "numberList",
  dlog: "debugLog",
  alog: "activityLog",
  elog: "enterpriseLog",
  dshb: "dashboard",
  rpt: "reports",
  smpp: "smpp",
  vc_cmpg: "voiceCampaign",
  vc_flmgr: "voiceFileManager",
  cfgnum: "configureNumbers",
  cfgivr: "configureIvr",
  vr_flmgr: "vrFileManager",
  wa_tmp: "whatsappTemplates",
  wa_cmpg: "whatsappCampaigns",
  sdbx: "sandbox",
  txnhis: "transactionHistory",
  apiky: "apikey",
  hme: "home",
  apidoc: "apidoc",
  grpmgmt: "groupManagement",
  sipmgmt: "sipManagement",
  segment: "segment",
  providers: "providers",
  routing: "routing",
  blacklist: "blacklist",
  dnd: "dnd",
  alert: "alerts",
  email_cfg: "emailConfiguration",
};

export function getScope(scopeName, permission) {
  if (scopes[scopeName] && permissions[permission])
    return scopes[scopeName] + "_" + permissions[permission];
  else return null;
}

export function createScopePermissions(scope) {
  let finalScope = {};
  if (scope) {
    let scopeArray = scope.split(" ");
    scopeArray.forEach((x) => {
      let i = x.lastIndexOf("_");
      let moduleName = nscopes[x.substring(0, i)];
      let newObj = {};
      let permission = npermissions[x.substring(i + 1)];
      newObj[permission] = true;
      if (finalScope[moduleName]) {
        finalScope[moduleName] = { ...finalScope[moduleName], ...newObj };
      } else {
        finalScope[moduleName] = newObj;
      }
    });
  }
  return finalScope;
}

//export default createScopePermissions;
