import { lazy } from "react";

// use lazy for better code splitting, a.k.a. load faster
const Logout = lazy(() => import("../pages/Logout"));
const ipwhitelistinguser = lazy(() => import("../pages/ipwhitelistinguser.js"));
const IPWhitelisting = lazy(() => import("../pages/IPWhitelisting.js"));
const AddIPAddresses = lazy(() => import("../pages/AddIPAddresses.js"));
const smsTemplateForm = lazy(() => import("../pages/smsTemplateForm"));
const SMSTemplateRouter = lazy(() => import("../pages/SMSTemplateRouter"));
const emailTemplatesList = lazy(() => import("../pages/emailTemplatesList"));
const emailTemplateForm = lazy(() => import("../pages/emailTemplateForm"));
const ListEMAILCampaigns = lazy(() => import("../pages/ListEMAILCampaigns"));
const EmailAddCampaigns = lazy(() => import("../pages/EmailAddCampaign"));
const ContentBlock = lazy(() => import("../pages/ContentFilterBlocked.js"));
const ContentFilter = lazy(() =>
  import("../admin/pages/Content-Filter-Outline.js")
);
const QuietTime = lazy(() => import("../pages/quietTime"));
const CreateQuiteTime = lazy(() =>
  import("../components/Forms/createQuietTime")
);

const ContactPage = lazy(() => import("../pages/ContactListPage"));
const ContactGroupPage = lazy(() => import("../pages/ContactGroupPage"));
const Forms = lazy(() => import("../pages/Forms"));
const Charts = lazy(() => import("../pages/Charts"));
const smsDashboard = lazy(() => import("../pages/SMSDashboard"));
const Reports = lazy(() => import("../pages/Reports"));
const Modals = lazy(() => import("../pages/Modals"));
const Page404 = lazy(() => import("../pages/404"));
const Blank = lazy(() => import("../pages/Blank"));
const RolesList = lazy(() => import("../pages/RolesList"));
const AddCampaign = lazy(() => import("../pages/AddCampaign"));
const RoleCreationForm = lazy(() => import("../pages/roleCreationForm"));
const listsmsCamp = lazy(() => import("../pages/ListSMSCampaign"));
const ViewSMSCampaign = lazy(() => import("../pages/ViewSMSCampaign"));
const ViewEMAILCampaign = lazy(() => import("../pages/ViewEMAILCampaign"));
const ViewWACampaign = lazy(() => import("../pages/ViewWACampaign.js"));
const Reschedule = lazy(() => import("../pages/reSchedulePage"));
const AddAccounts = lazy(() => import("../pages/EnterpriseCreation"));
const SenderAddresses = lazy(() => import("../pages/SenderAddresses"));
const EmailAddresses = lazy(() => import("../pages/EmailAddresses"));
const AddSenderIdForm = lazy(() => import("../pages/AddSenderIdForm"));
const AddEmailIdForm = lazy(() => import("../pages/AddEmailIdForm"));
const SMPPSession = lazy(() => import("../pages/smppSession"));
const Wallets = lazy(() => import("../pages/Wallets"));
const TransferCredits = lazy(() => import("../pages/TransferCredits"));
const UsersList = lazy(() => import("../pages/UsersList"));
const UserCreationForm = lazy(() => import("../pages/UserCreationForm"));
const HomeDashboard = lazy(() => import("../pages/HomeDashboard"));
const Insights = lazy(() => import("../pages/Insights"));
const StudioAI = lazy(() => import("../studio/modules/studio-ai"));

const PackagesBillingAndPricing = lazy(() =>
  import("../pages/PackagesBillingAndPricing")
);
const RateSheetsBillingAndPricing = lazy(() =>
  import("../pages/RateSheetsBillingAndPricing")
);
const RateSheetsBillingAndPricingForm = lazy(() =>
  import("../pages/RateSheetsBillingAndPricingForm")
);
const WhatsappCampaign = lazy(() => import("../pages/WhatsappMyCampaigns"));
const WhatsappCampaignForm = lazy(() =>
  import("../pages/WhatsappMyCampaignsForm")
);
const DistributionList = lazy(() => import("../pages/DistributionList"));
const SenderAddress = lazy(() =>
  import("../admin/pages/SenderAddress_PlatformAdmin")
);

const EmailAddress = lazy(() =>
  import("../admin/pages/EmailAddress_PlatformAdmin")
);
const RegisterSenderAddressesList = lazy(() =>
  import("../pages/RegisterSenderAddress")
);

const RegisterEmailAddressesList = lazy(() =>
  import("../pages/RegisterEmailAddress")
);
const unsubscribedlists = lazy(() => import("../pages/unsubscribedlist"));
const SenderAddrsInroduction = lazy(() =>
  import("../pages/SenderAddressIntroduction")
);
const EmailAddrsInroduction = lazy(() =>
  import("../pages/EmailAddressIntroduction")
);

const DistributionListForm = lazy(() =>
  import("../pages/DistributionListForm")
);
const PackageCreationForm = lazy(() =>
  import("../pages/PackageCreationFormNew")
);
const ContactForm = lazy(() => import("../pages/ContactForm"));
// const ContactNewForm = lazy(() => import("../pages/ContactNewForm.js"));

const ContactGroupForm = lazy(() => import("../pages/ContactGroupForm"));
const EditSenderAddressSpecifications = lazy(() =>
  import("../pages/EditSenderAddressSpecifications")
);
const EditEmailAddressSpecification = lazy(() =>
  import("../pages/EditEmailAddressSpecification")
);

const Accounts = lazy(() => import("../pages/Accounts"));
const Packages = lazy(() => import("../pages/PackagesNew"));
const GettingStarted = lazy(() => import("../pages/WhatsappLanding"));
const ViewRateSheet = lazy(() => import("../pages/ViewRatesheetNew"));
const WhatsappTemplateList = lazy(() =>
  import("../pages/WhatsappTemplateList")
);
const WhatsappTemplateCreationForm = lazy(() =>
  import("../pages/WhatsappTemplateCreationForm")
);
const WhatsappSandboxPage = lazy(() => import("../pages/WhatsappSandboxPage"));
const EditWhatsappProfiles = lazy(() => import("../pages/EditProfile.js"));
const WhatsappProfileList = lazy(() =>
  import("../pages/WhatsappProfileList.js")
);
const Invoicing = lazy(() => import("../pages/Invoicing"));
const InvoicingDetails = lazy(() => import("../pages/InvoicingDetails"));
const NumbersListViewEA = lazy(() => import("../pages/NumberListViewEA"));
const NumbersListViewPA = lazy(() => import("../admin/pages/Numbers"));
const BuyNumber = lazy(() => import("../pages/BuyNumber"));
const EditNumber = lazy(() => import("../pages/EditNumber"));
const EditNumberPA = lazy(() => import("../admin/components/EditNumberPA"));
const NumberSettings = lazy(() => import("../admin/components/NumberSettings"));
const UploadNumberPA = lazy(() => import("../admin/components/UploadNumberPA"));
const TransferPackage = lazy(() => import("../pages/TransferPackage"));
const ProviderReports = lazy(() => import("../pages/ProviderReports"));
const LogActivity = lazy(() => import("../admin/pages/LogActivity"));
const LogEnterprise = lazy(() => import("../admin/pages/LogEnterprise"));
const LogDebug = lazy(() => import("../admin/pages/LogDebug"));
const ChatbotAPI = lazy(() => import("../pages/ChatbotAPI"));
const Notification = lazy(() => import("../pages/Notification"));
const NotificationPA = lazy(() => import("../pages/NotificationPA"));
const ProviderPage = lazy(() => import("../admin/pages/Provider/Provider"));
const ProviderForm = lazy(() => import("../admin/pages/Provider/ProviderForm"));
const Routing = lazy(() => import("../admin/pages/Routing/Routing"));

const StudioDashboard = lazy(() => import("../studio"));
const StudioEditor = lazy(() => import("../studio/modules/studio-editor"));
const StudioLogs = lazy(() => import("../studio/modules/studio-logs"));

const RuleCreationForm = lazy(() =>
  import("../admin/pages/Routing/RuleCreationPage")
);
const ConfigureSmpp = lazy(() =>
  import("../admin/pages/Provider/ConfigureSmpp")
);
const ConfigureSmppForm = lazy(() =>
  import("../admin/pages/Provider/ConfigureSmppForm")
);
const ConfigureSip = lazy(() => import("../admin/pages/Provider/ConfigureSip"));
const ConfigureSipForm = lazy(() =>
  import("../admin/pages/Provider/ConfigureSipForm")
);
const RatePlan = lazy(() => import("../admin/pages/Provider/RatePlan"));
const GroupList = lazy(() => import("../pages/GroupList"));
const AddGroupForm = lazy(() => import("../pages/AddGroupForm"));
const IVRList = lazy(() => import("../pages/IVRList"));
const AddVirtualReceptionistForm = lazy(() =>
  import("../pages/AddVirtualReceptionist")
);
const VoiceNumberList = lazy(() => import("../pages/VoiceNumberList"));
const NumberConfigurationForm = lazy(() =>
  import("../pages/NumberConfigurationPage")
);
const NumberView = lazy(() => import("../admin/pages/NumberView"));
const IVRView = lazy(() => import("../admin/pages/IVRView"));
const VirtualReceptionistActivityLogs = lazy(() =>
  import("../admin/pages/VirtualReceptionistActivityLogs")
);
const VoiceCampaign = lazy(() => import("../pages/VoiceCampaign"));
const VoiceCampaignForm = lazy(() => import("../pages/VoiceCampaignForms"));
const FileManagerList = lazy(() => import("../pages/FileManagerList"));
const UploadAnnouncement = lazy(() => import("../pages/UploadAnnouncement"));
const VirtualReceptionistActivityLogsEA = lazy(() =>
  import("../pages/VirtualReceptionistActivityLogs")
);
const Dialler = lazy(() => import("../pages/Dialler"));

const RCSCampaign = lazy(() => import("../pages/RCSCampaign"));
const RCSCampaignForm = lazy(() => import("../pages/RCSCampaignForm"));
const ServiceKey = lazy(() => import("../pages/ServiceKey"));
const RCSAgentProfiles = lazy(() =>
  import("../admin/components/AgentProfiles")
);
const RCSCustomerProfiles = lazy(() => import("../pages/CustomerProfiles"));
const RCSConfigureCustomerProfiles = lazy(() =>
  import("../pages/ConfigureCustomerProfile")
);
const APIDocumentationEA = lazy(() => import("../pages/APIDocumentationEA"));
const APIDocumentationPA = lazy(() => import("../pages/APIDocumentationPA"));

const SegmentationList = lazy(() =>
  import("../components/Segmentation/SegmentationList")
);
const SegmentationForm = lazy(() =>
  import("../components/Segmentation/SegmentationForm")
);
const WhatsAppProfileInfo = lazy(() => import("../pages/WhatsAppProfileInfo"));
const WhatsAppAccountDetails = lazy(() =>
  import("../pages/WhatsAppAccountDetails")
);
const ViewSenderAddressDetails = lazy(() =>
  import("../pages/ViewSenderAddressDetails")
);

const BlacklistManagementListing = lazy(() =>
  import("../pages/BlacklistManagementListing")
);
const DNDListing = lazy(() => import("../pages/DNDListing"));
const UploadDNDNumbers = lazy(() => import("../pages/UploadDNDNumbers"));
const ViewRCSCampaign = lazy(() => import("../pages/ViewRCSCampaign.js"));
/**
 * ⚠ These are internal routes!
 * They will be rendered inside the app, using the default `containers/Layout`.
 * If you want to add a route to, let's say, a landing page, you should add
 * it to the `App`'s router, exactly like `Login`, `CreateAccount` and other pages
 * are routed.
 *
 * If you're looking for the links rendered in the SidebarContent, go to
 * `routes/sidebar.js`
 */
const routes = [
  {
    path: "/studio/dashboard/:type/:templateId?",
    component: StudioDashboard,
  },
  {
    path: "/studio/editor/:id",
    component: StudioEditor,
  },
  {
    path: "/studio/editor/ai/:id",
    component: StudioAI,
  },
  {
    path: "/studio/editor/logs/:id",
    component: StudioLogs,
  },
  {
    path: "/studio/editor/ai/:id",
    component: StudioAI,
  },
  {
    path: "/logout",
    component: Logout,
  },
  {
    path: "/policy/QuietTime",
    component: QuietTime,
  },

  {
    path: "/policy/QuietTime/:method",
    component: CreateQuiteTime,
  },

  {
    path: "/policy/QuietTime/:method/:id",
    component: CreateQuiteTime,
  },
  {
    path: "home",
    component: HomeDashboard,
  },
  {
    path: "/sms/my-campaigns",
    component: listsmsCamp,
  },
  {
    path: "/email/my-campaigns",
    component: ListEMAILCampaigns,
  },
  {
    path: "/email/my-campaigns/add-campaign/:method",
    component: EmailAddCampaigns,
  },
  {
    path: "/email/my-campaigns/add-campaign/:method/:id",
    component: EmailAddCampaigns,
  },
  {
    path: "/email/my-campaigns/reschedule/:id",
    component: Reschedule,
  },
  {
    path: "/sms/my-campaigns/view-campaign/:id/:recurrenceId",
    component: ViewSMSCampaign,
  },
  {
    path: "/email/my-campaigns/view-campaign/:id/:recurrenceId",
    component: ViewEMAILCampaign,
  },
  {
    path: "/rcs/my-campaigns/view-campaign/:id/:recurrenceId",
    component: ViewRCSCampaign,
  },

  //policy routes
  {
    path: "/policy/contentfilter",
    component: ContentFilter,
  },
  {
    path: "/whatsapp/my-campaigns/view-campaign/:id/:recurrenceId",
    component: ViewWACampaign,
  },
  {
    path: "/sms/my-campaigns/reschedule/:id/:recurrenceId",
    component: Reschedule,
  },
  {
    path: "/sms/my-campaigns/add-campaign/:method",
    component: AddCampaign,
  },
  {
    path: "/sms/my-campaigns/add-campaign/:method/:id/:recurrenceId",
    component: AddCampaign,
  },
  {
    path: "/sms/sender-addresses",
    component: SenderAddresses,
  },
  {
    path: "/email/email-addresses",
    component: EmailAddresses,
  },

  {
    path: "/sms/Register/sender-addresses",
    component: RegisterSenderAddressesList,
  },
  {
    path: "/sms/Register/sender-addresses/view/:id",
    component: ViewSenderAddressDetails,
  },

  {
    path: "/email/Register/email-addresses",
    component: RegisterEmailAddressesList,
  },

  {
    path: "/email/unsubscribe",
    component: unsubscribedlists,
  },

  {
    path: "/email/Register/email-addresses/:action",
    component: AddEmailIdForm,
  },
  {
    path: "/email/Register/email-addresses/:action/:emailId",
    component: AddEmailIdForm,
  },

  {
    path: "/sms/introduction/sender-addresses",
    component: SenderAddrsInroduction,
  },
  {
    path: "/email/introduction/email-addresses",
    component: EmailAddrsInroduction,
  },
  {
    path: "/sms/Register/sender-addresses/:action",
    component: AddSenderIdForm,
  },
  {
    path: "/sms/Register/sender-addresses/:action/:senderId",
    component: AddSenderIdForm,
  },
  {
    path: "/sms/manage-templates",
    component: SMSTemplateRouter,
  },
  {
    path: "/sms/manage-templates/:id",
    component: smsTemplateForm,
  },
  {
    path: "/sms/manage-templates/:id/:templateId",
    component: smsTemplateForm,
  },
  {
    path: "/email/email-templates",
    component: emailTemplatesList,
  },
  {
    path: "/email/email-templates/:id",
    component: emailTemplateForm,
  },
  {
    path: "/email/email-templates/:id/:templateId",
    component: emailTemplateForm,
  },
  {
    path: "/whatsapp/my-campaigns/",
    component: WhatsappCampaign,
  },
  {
    path: "/whatsapp/my-campaigns/add-campaign/:method",
    component: WhatsappCampaignForm,
  },
  {
    path: "/whatsapp/my-campaigns/add-campaign/:method/:id/:recurrenceId",
    component: WhatsappCampaignForm,
  },
  {
    path: "/whatsapp/sandbox",
    component: WhatsappSandboxPage,
  },
  {
    path: "/forms",
    component: Forms,
  },
  {
    path: "/charts",
    component: Charts,
  },
  {
    path: "/sms/dashboard",
    component: smsDashboard,
  },
  {
    path: "/modals",
    component: Modals,
  },
  {
    path: "/404",
    component: Page404,
  },
  {
    path: "/blank",
    component: Blank,
  },
  {
    path: "/user-management/roles",
    component: RolesList,
  },
  {
    path: "/user-management/roles/:action",
    component: RoleCreationForm,
  },
  {
    path: "/user-management/roles/:action/:id",
    component: RoleCreationForm,
  },
  {
    path: "/accounts",
    component: Accounts,
  },
  {
    path: "/accounts/ipwhitelisting/:id",
    component: IPWhitelisting,
  },
  {
    path: "/accounts/ipwhitelistinguser/:id",
    component: ipwhitelistinguser,
  },
  {
    path: "/accounts/addipaddresses/:id",
    component: AddIPAddresses,
  },

  {
    path: "/accounts/:action",
    component: AddAccounts,
  },
  {
    path: "/accounts/:action/:accountId",
    component: AddAccounts,
  },
  {
    path: "/billing-pricing/wallets",
    component: Wallets,
  },
  {
    path: "/billing-pricing/packages",
    component: PackagesBillingAndPricing,
  },
  {
    path: "/billing-pricing/rate-sheets",
    component: RateSheetsBillingAndPricing,
  },
  {
    path: "/billing-pricing/rate-sheets/:action/",
    component: RateSheetsBillingAndPricingForm,
  },
  {
    path: "/billing-pricing/rate-sheets/:action/:id",
    component: RateSheetsBillingAndPricingForm,
  },
  {
    path: "/billing-pricing/rate-sheets/add-rate-sheet/:action/:id",
    component: RateSheetsBillingAndPricingForm,
  },
  {
    path: "/logs/activity",
    component: LogActivity,
  },
  {
    path: "/logs/enterprise",
    component: LogEnterprise,
  },
  {
    path: "/logs/debug",
    component: LogDebug,
  },
  // {
  //   path: "/home/wallets",
  //   component: Wallets,
  // },
  {
    path: "/billing-pricing/invoicing",
    component: Invoicing,
  },
  {
    path: "/billing-pricing/invoicing/invoice-history/:id",
    component: InvoicingDetails,
  },
  {
    path: "/NumbersEA",
    component: NumbersListViewEA,
  },
  {
    path: "/NumbersPA",
    component: NumbersListViewPA,
  },
  {
    path: "/NumbersEA/buy",
    component: BuyNumber,
  },
  {
    path: "/NumbersEA/edit/:id",
    component: EditNumber,
  },
  {
    path: "/NumbersPA/edit/:id",
    component: EditNumberPA,
  },
  {
    path: "/NumbersPA/view/:id",
    component: EditNumber,
  },
  {
    path: "/NumbersPA/settings",
    component: NumberSettings,
  },
  {
    path: "/numbersPA/upload",
    component: UploadNumberPA,
  },
  {
    path: "/transfercredits",
    component: TransferCredits,
  },
  {
    path: "/user-management/users",
    component: UsersList,
  },
  {
    path: "/user-management/users/_create",
    component: UserCreationForm,
  },
  {
    path: "/user-management/users/:id",
    component: UserCreationForm,
  },
  {
    path: "/contacts/distribution-list",
    component: DistributionList,
  },
  { path: "/insights/reports", component: Reports },
  // { path: "/insights/usage-reports", component: UsageReports },
  { path: "/insights/usage-reports", component: Reports },
  { path: "/insights/provider-reports", component: ProviderReports },
  // {
  //   path: "/Sms/reports",
  //   component: Reports,
  // },
  {
    path: "/contacts/distribution-list/_create",
    component: DistributionListForm,
  },
  {
    path: "/contacts/distribution-list/:id",
    component: DistributionListForm,
  },
  {
    path: "/contacts/contact-list",
    component: ContactPage,
  },
  {
    path: "/contacts/contact-list/_create",
    component: ContactForm,
  },
  {
    path: "/contacts/contact-list/:id",
    component: ContactForm,
  },
  {
    path: "/contacts/contact-group",
    component: ContactGroupPage,
  },
  {
    path: "/contacts/contact-group/_create",
    component: ContactGroupForm,
  },
  {
    path: "/contacts/contact-group/:id",
    component: ContactGroupForm,
  },
  {
    path: "/sms/sender-address-specifications",
    component: SenderAddress,
  },

  {
    path: "/email/email-address-specifications",
    component: EmailAddress,
  },

  {
    path: "/sms/sender-address-specifications/:action",
    component: EditSenderAddressSpecifications,
  },

  {
    path: "/sms/sender-address-specifications/:action/:countryCode",
    component: EditSenderAddressSpecifications,
  },
  {
    path: "/email/email-address-specifications/:action",
    component: EditEmailAddressSpecification,
  },

  {
    path: "/email/email-address-specifications/:action/:countryCode",
    component: EditEmailAddressSpecification,
  },
  {
    path: "/sms/smpp-session",
    component: SMPPSession,
  },
  {
    path: "/insights/dashboard",
    component: Insights,
  },
  {
    path: "/myplans/packages",
    component: Packages,
  },
  //Whatsapp routes
  // {
  //   path: "/whatsapp/getting-started",
  //   component: GettingStarted,
  // },
  //TODO - remove this after demo and uncomment the above path
  {
    path: "/whatsapp/getting-started",
    component: WhatsappProfileList,
  },
  {
    path: "/billing-pricing/packages/:action",
    component: PackageCreationForm,
  },
  {
    path: "/billing-pricing/packages/:action/:id",
    component: PackageCreationForm,
  },
  {
    path: "/billing-pricing/packages/add-package/:action/:id",
    component: PackageCreationForm,
  },
  {
    path: "/billing-pricing/packages/transfer-package",
    component: TransferPackage,
  },
  {
    path: "/myplans/ratesheets",
    component: ViewRateSheet,
  },
  {
    path: "/whatsapp/templates",
    component: WhatsappTemplateList,
  },
  {
    path: "/whatsapp/templates/:action/:id",
    component: WhatsappTemplateCreationForm,
  },
  {
    path: "/whatsapp/templates/:action",
    component: WhatsappTemplateCreationForm,
  },
  {
    path: "/whatsapp/getting-started",
    component: GettingStarted,
  },
  {
    path: "/whatsapp/profiles",
    component: WhatsappProfileList,
  },
  {
    path: "/whatsapp/profiles/:id",
    component: EditWhatsappProfiles,
  },
  {
    path: "/whatsapp/my-campaigns/reschedule/:id/:recurrenceId",
    component: Reschedule,
  },
  {
    path: "/voice/my-campaigns/reschedule/:id/:recurrenceId",
    component: Reschedule,
  },
  {
    path: "/email/my-campaigns/reschedule/:id/:recurrenceId",
    component: Reschedule,
  },
  {
    path: "/chat",
    component: ChatbotAPI,
  },
  {
    path: "/notification",
    component: Notification,
  },
  {
    path: "/Routing/:channel/:id/:key",
    component: RuleCreationForm,
  },
  {
    path: "/provider/:channel/configure/rateplan/:name/:key",
    component: RatePlan,
  },
  {
    path: "/Providers",
    component: ProviderPage,
  },
  {
    path: "/provider-form/:channel/:id",
    component: ProviderForm,
  },
  {
    path: "/provider-form/:channel/:id/:key",
    component: ProviderForm,
  },
  {
    path: "/provider/sms/configure",
    component: ConfigureSmpp,
  },
  {
    path: "/provider/sms/configure/:id",
    component: ConfigureSmpp,
  },
  {
    path: "/provider/voice/configure/:id",
    component: ConfigureSip,
  },
  {
    path: "/provider/voice/configure/:providerId/:bindId/:method",
    component: ConfigureSipForm,
  },
  {
    path: "/provider/voice/configure/:providerId/:method",
    component: ConfigureSipForm,
  },
  {
    path: "/Routing",
    component: Routing,
  },
  {
    path: "/provider/sms/configure/:providerId/:bindId/:method",
    component: ConfigureSmppForm,
  },
  {
    path: "/provider/sms/configure/:providerId/:method",
    component: ConfigureSmppForm,
  },

  {
    path: "/provider/configure/rateplan/:id/:key",
    component: RatePlan,
  },
  {
    path: "/Routing/:channel/:id",
    component: RuleCreationForm,
  },
  {
    path: "/notification_PA",
    component: NotificationPA,
  },
  {
    path: "/voice/groups",
    component: GroupList,
  },
  {
    path: "/voice/groups/:id",
    component: AddGroupForm,
  },
  {
    path: "/voice/groups/:id/:key",
    component: AddGroupForm,
  },
  {
    path: "/voice/voicerecepientlist",
    component: IVRList,
  },
  {
    path: "/voice/voicerecepientlist/:id",
    component: AddVirtualReceptionistForm,
  },
  {
    path: "/voice/voicerecepientlist/:id/:key",
    component: AddVirtualReceptionistForm,
  },
  {
    path: "/voice/numbers",
    component: VoiceNumberList,
  },
  {
    path: "/voice/numbers/:cc/:number/:id",
    component: NumberConfigurationForm,
  },
  {
    path: "/voice/numbers/:cc/:number/:id/:key",
    component: NumberConfigurationForm,
  },
  {
    path: "/Routing/:id/:value",
    component: RuleCreationForm,
  },
  { path: "/notification_PA", component: NotificationPA },
  { path: "/voice/number-view", component: NumberView },
  { path: "/voice/ivr-view", component: IVRView },
  { path: "/voice/admin/activity", component: VirtualReceptionistActivityLogs },
  {
    path: "/voice/campaign",
    component: VoiceCampaign,
  },
  { path: "/voice/campaign/:method", component: VoiceCampaignForm },
  { path: "/voice/campaign/:method/:id", component: VoiceCampaignForm },
  { path: "/voice/file-manager", component: FileManagerList },
  { path: "/voice/file-manager/upload", component: UploadAnnouncement },

  { path: "/voice/activity", component: VirtualReceptionistActivityLogsEA },
  { path: "/voice/dialler", component: Dialler },

  { path: "/rcs/campaign", component: RCSCampaign },
  { path: "/rcs/campaign-form/:id", component: RCSCampaignForm },
  { path: "/rcs/service-key", component: ServiceKey },
  { path: "/rcs/customer-profiles", component: RCSCustomerProfiles },
  {
    path: "/rcs/customer-profiles/:action",
    component: RCSConfigureCustomerProfiles,
  },
  {
    path: "/rcs/customer-profiles/:action/:id",
    component: RCSConfigureCustomerProfiles,
  },
  { path: "/rcs/agent-profiles", component: RCSAgentProfiles },
  { path: "/api-documentation", component: APIDocumentationEA },
  { path: "/admin/api-documentation", component: APIDocumentationPA },
  {
    path: "/email/my-campaigns/add-campaign/:method",
    component: EmailAddCampaigns,
  },
  {
    path: "/email/my-campaigns/add-campaign/:method/:id/:recurrenceId",
    component: EmailAddCampaigns,
  },
  {
    path: "/email/my-campaigns/reschedule/:id",
    component: Reschedule,
  },
  {
    path: "/email/email-addresses",
    component: EmailAddresses,
  },
  {
    path: "/email/Register/email-addresses",
    component: RegisterEmailAddressesList,
  },

  {
    path: "/email/unsubscribe",
    component: unsubscribedlists,
  },

  {
    path: "/email/Register/email-addresses/:action",
    component: AddEmailIdForm,
  },
  {
    path: "/email/Register/email-addresses/:action/:emailId",
    component: AddEmailIdForm,
  },
  {
    path: "/email/introduction/email-addresses",
    component: EmailAddrsInroduction,
  },
  {
    path: "/email/email-templates",
    component: emailTemplatesList,
  },
  {
    path: "/email/email-templates/:id",
    component: emailTemplateForm,
  },
  {
    path: "/email/email-templates/:id/:templateId",
    component: emailTemplateForm,
  },
  {
    path: "/email/email-address-specifications",
    component: EmailAddress,
  },
  {
    path: "/email/email-address-specifications/:action",
    component: EditEmailAddressSpecification,
  },

  {
    path: "/email/email-address-specifications/:action/:countryCode",
    component: EditEmailAddressSpecification,
  },

  { path: "/whatsapp/profiles-info", component: WhatsAppProfileInfo },
  {
    path: "/whatsapp/profiles-info/account-details/:id",
    component: WhatsAppAccountDetails,
  },
  {
    path: "/segmentation",
    component: SegmentationList,
  },
  {
    path: "/segmentation/:method",
    component: SegmentationForm,
  },
  {
    path: "/segmentation/:method/:id",
    component: SegmentationForm,
  },
  {
    path: "/policy/blacklist/:channel",
    component: BlacklistManagementListing,
  },
  {
    path: "/policy/dnd/:channel",
    component: DNDListing,
  },
  {
    path: "/policy/dnd/:channel/upload",
    component: UploadDNDNumbers,
  },
];

export default routes;
