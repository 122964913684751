import { lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import ForwardRoute from "./components/Routes/ForwardRoute";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useTheme } from "./context/ThemeContext";
import PaymentTimer from "./pages/PaymentTimer";

const HeaderLayout = lazy(() => import("./containers/headerLayout"));
const EmailVerification = lazy(() => import("./pages/EmailVerification"));
const MFAOtpVerification = lazy(() => import("./admin/pages/MfaOtp"));
const SetPassword = lazy(() => import("./pages/SetPassword"));
const ForwardActivationLink = lazy(() =>
  import("./pages/ForwardActivationLink")
);
const ResetPassword = lazy(() => import("./pages/RePassword"));
const SuccessOTP = lazy(() => import("./admin/pages/SuccessOtp"));
const FailureOTP = lazy(() => import("./admin/pages/FailureOtp"));
const TransactionCompleteCallback = lazy(() =>
  import("./pages/TransactionCompleteCallback")
);
function App() {
  const { i18n } = useTranslation();
  const val = i18n.resolvedLanguage;
  const { isThemeLoading, isAuthConfigLoading } = useTheme();

  useEffect(() => {
    if (val === "ar") {
      document.body.dir = "rtl";
    } else {
      document.body.dir = "ltr";
    }
  }, [val, i18n]);

  return (
    <>
      {!isThemeLoading && !isAuthConfigLoading && (
        <Router>
          <AccessibleNavigationAnnouncer />
          <Routes>
            <Route path="/auth/*" element={<ForwardRoute />} />
            {/* Place new routes over this */}
            <Route path="/mfaverification" element={<MFAOtpVerification />} />
            <Route path="/successOTP" element={<SuccessOTP />} />
            <Route path="/failureOTP/:errorMessage" element={<FailureOTP />} />
            <Route
              path="/paymentComplete"
              element={<TransactionCompleteCallback />}
            />
            <Route
              path="/:userid/:usertoken/:mobileNumber/_vlink"
              element={<SetPassword />}
            />
            <Route
              path="/activate/:nanoId"
              element={<ForwardActivationLink />}
            />
            <Route
              path="/:userid/:usertoken/_rlink"
              element={<ResetPassword />}
            />
            <Route path="/app/header/*" element={<HeaderLayout />} />
            <Route path="/app/*" element={<ProtectedRoute />} />
            <Route path="/verify-email" element={<EmailVerification />} />
            <Route path="/payment-timer" element={<PaymentTimer />} />
            <Route path="*" element={<Navigate to="/auth" />} />
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;
