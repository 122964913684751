import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./assets/css/tailwind.output.css";
import "./assets/css/font-awesome.min.css";
import App from "./App";
import { SidebarProvider } from "./context/SidebarContext";
import { SnackbarProvider } from "./context/SnackbarContext";
import { AuthProvider } from "./context/AuthContext";
import ThemedSuspense from "./components/ThemedSuspense";
// import { Windmill } from "@windmill/react-ui";
import StepContext from "../src/context/MultiStepFormContext";
import "../src/translations/i18n";
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";

import { ThemeProvider } from "./context/ThemeContext";
const queryClient = new QueryClient();

const container = document.getElementById("root");
const root = createRoot(container);

if (process.env.NODE_ENV === "development") {
  const { worker } = require("./mocks/browser");
  worker.start();
}

root.render(
  <QueryClientProvider client={queryClient}>
    <SidebarProvider>
      <SnackbarProvider>
        {/* <StripeProvider> */}
        <AuthProvider>
          <Suspense fallback={<ThemedSuspense />}>
            {/* <Windmill usePreferences> */}
            <StepContext>
              <ThemeProvider>
                <App />
              </ThemeProvider>
            </StepContext>
            {/* </Windmill> */}
          </Suspense>
        </AuthProvider>
        {/* </StripeProvider> */}
      </SnackbarProvider>
    </SidebarProvider>
    {/* REACT QUERY DEVTOOL */}
    {/* <ReactQueryDevtools initialIsOpen={true} /> */}
  </QueryClientProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
