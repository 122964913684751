import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import TRANSLATIONS_EN from "./en.json";
import TRANSLATIONS_ES from "./es.json";
import TRANSLATIONS_POR from "./por.json";
import TRANSLATIONS_AR from "./ar.json";
import TRANSLATIONS_FR from "./fr.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    load: "currentOnly",
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      es: {
        translation: TRANSLATIONS_ES,
      },
      pt: {
        translation: TRANSLATIONS_POR,
      },
      ar: {
        translation: TRANSLATIONS_AR,
      },
      fr: {
        translation: TRANSLATIONS_FR,
      },
    },
  });
