//import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

function ThemedSuspense() {
  return (
    <div className="w-full h-screen flex justify-center items-center text-center p-6">
      <CircularProgress />
    </div>
  );
}

export default ThemedSuspense;
