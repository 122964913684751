import React, { useContext, useEffect } from "react";
import { MenuIcon, NotificationIcon } from "../icons";
import { SidebarContext } from "../context/SidebarContext";
import userIcon from "../assets/img/userIcon.png";
import { WalletIcon } from "../icons/index.js";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import { useQuery, useInfiniteQuery } from "react-query";
import { getBalance } from "../lib/wallet-service.js";
import { getNotifications, getUnreadCount } from "../lib/notification-apis";
import NotificationDropdown from "./Dropdowns/NotificationDropdown.js";
import { roleName } from "../constants_map";
import ComvivaLogo from "../assets/img/comviva-logo.svg";
import { useTheme } from "../context/ThemeContext.js";
import axios from "axios";
import getIP from "../getIP.js";

const IP = getIP();

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { theme, setTheme } = useTheme();
  const [showAccountDropDown, setAccountShowDropDown] = React.useState(false);
  const [showCreditsDropDown, setShowCreditsDropDown] = React.useState(false);
  const [showAlertDropDown, setShowAlertDropDown] = React.useState(false);

  const { toggleSidebar } = useContext(SidebarContext);
  const { logout, userData, userDetails, userScope, userRole, globalConfig } =
    useContext(AuthContext);

  // useEffect(() => {
  //   const handleUnload = () => {
  //     console.log("logout while logging out");
  //     window.localStorage.clear();
  //     logout();
  //   };

  //   console.log("adding event listener");
  //   window.addEventListener("beforeunload", handleUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleUnload);
  //   };
  // }, []);

  const enableWallet =
    globalConfig.find((e) => e.key === "enableWallet")?.value || "true";

  const loggedInRole =
    userRole === roleName.enterpriseAdmin
      ? "EA"
      : userRole === roleName.enterpriseUser
      ? "EU"
      : userRole === roleName.platformAdmin
      ? "PA"
      : "NA";

  const { data, status, isLoading } = useQuery(
    ["getBalance", userDetails, userDetails?.sub],
    getBalance,
    {
      enabled: loggedInRole !== "PA",
      refetchInterval: 4000,
    }
  );

  const url = `${IP}/api/v1/accounts/users?pgsize=10&pgindex=1`;
  const fetchgroupID = async ({ queryKey }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${queryKey[1]}`,
        },
      };
      const res = await axios.get(url, config);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  const { data: groupid } = useQuery(
    ["getAccountListingData", userDetails?.token],
    fetchgroupID
  );

  const groupID = groupid?.data[0]?.groupId;

  const {
    data: notificationsData,
    isLoading: isNotificationLoading,
    refetch: notificationRefetch,
  } = useInfiniteQuery(
    ["getNotifications", userDetails, userDetails?.sub],
    getNotifications,
    { enabled: userScope?.alerts?.read }
  );

  const { data: unreadCount, refetch: unreadCountRefetch } = useQuery(
    ["getUnreadcount", userDetails, userDetails?.sub],
    getUnreadCount
  );

  let style = {
    accountDropDown: `absolute ${showAccountDropDown ? "flex" : "hidden"}
    top-6 bg-white ltr:right-6 rtl:left-6 bg-white flex flex-col mt-[0.65rem] rounded-xl text-sm shadow-md p-5 place-content-center`,
    creditsDropDown: `absolute ${showCreditsDropDown ? "flex" : "hidden"}
    top-[25px] bg-white flex flex-col mt-[1.1rem] px-3 box-border text-xs shadow-md ltr:left-0 rtl:right-1 place-content-center rounded-lg`,
  };

  const renderWalletPage = () => {
    navigate("/app/header/wallets");
    setShowCreditsDropDown(!showCreditsDropDown);
  };

  const renderTransferCreditsPage = () => {
    navigate("/app/header/transfercredits");
    setShowCreditsDropDown(!showCreditsDropDown);
  };

  const hexToRgb = (hex) => {
    hex = hex.replace(/^#/, "");

    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return `${r}, ${g}, ${b}`;
  };
  return (
    <div
      className="z-10 flex border-[1px] justify-between border-solid border-[#D2D2D2] flex-grow sticky bg-white top-0 w-full"
      style={{ height: 85 }}
    >
      <div className="flex items-center w-[151px]">
        <div className="w-[30px] h-[85px] lg:hidden ltr:ml-4 rtl:mr-4">
          <MenuIcon
            onClick={toggleSidebar}
            height={85}
            width={35}
            className="cursor-pointer"
          />
        </div>
        <div className="h-12 ">
          <img
            className=" h-full w-auto mx-8"
            src={theme.logos ? theme.logos : ComvivaLogo}
            alt="Logo"
          />
        </div>
      </div>
      {theme.announcementFlag === "Enable" &&
        (theme.announcementText !== "" || !theme.announcementText) && (
          <div
            style={{ color: "#800000" }}
            className="flex h-8 md:ltr:ml-20 flex-wrap break-all md:rtl:mr-20 items-center self-center place-items-center rounded gap-2"
            rel="noreferrer"
          >
            <div className="flex flex-row gap-2">
              {theme.announcementText}
              {theme.display && theme.url && (
                <a
                  href={theme.url}
                  target="_blank"
                  className="text-blue-400 ml-2"
                  rel="noopener noreferrer"
                >
                  {theme.display}
                </a>
              )}
              <button
                type="button"
                onClick={() => {
                  setTheme({ ...theme, announcementFlag: "Disable" });
                }}
                className="font-bold text-sm"
              >
                x
              </button>
            </div>
          </div>
        )}
      <div className="self-center">
        <div className="flex items-center justify-end">
          {loggedInRole === "EA" || loggedInRole === "EU" ? (
            <div
              onClick={() => setShowCreditsDropDown(true)}
              onMouseLeave={() => setShowCreditsDropDown(false)}
              className="relative py-1 rounded-[8px] bg-opacity-[0.06] flex items-center cursor-pointer"
              style={{
                backgroundColor: `rgba(${hexToRgb(theme.secondaryColor)}, 0.1)`,
              }}
            >
              <div className="ltr:ml-3 rtl:mr-3 py-3">
                <WalletIcon
                  className="w-6 h-5"
                  style={{ color: theme.secondaryColor }}
                />
              </div>
              <div
                onClick={() => setShowCreditsDropDown(!showCreditsDropDown)}
                className="flex flex-col ml-2 text mx-4 relative"
              >
                <h1
                  style={{ color: theme.secondaryColor, opacity: "75%" }}
                  className=" text-xs ltr:pr-2 rtl:pl-2"
                >
                  {t("_Balance")}
                </h1>
                {status === "success" && !isLoading && (
                  <h1
                    style={{ color: theme.secondaryColor }}
                    className=" font-bold opacity-100 text-sm"
                  >
                    {Number(data?.data?.balance).toLocaleString("en", {
                      minimumFractionDigits: 2,
                    })}
                  </h1>
                )}
              </div>
              <div style={{ width: 150 }} className={style.creditsDropDown}>
                {enableWallet === "true" && (
                  <h1
                    className="mx-3 mt-3 mb-2 cursor-pointer font-semibold"
                    onClick={() => renderWalletPage()}
                  >
                    {t("_My_wallet")}
                  </h1>
                )}
                <h1
                  className={`m-3 cursor-pointer font-semibold ${
                    !userScope?.wallets?.transfer &&
                    `opacity-50 cursor-not-allowed`
                  }`}
                  onClick={() => {
                    if (userScope?.wallets?.transfer)
                      renderTransferCreditsPage();
                  }}
                >
                  {t("_Transfer_credits")}
                </h1>
              </div>
            </div>
          ) : null}
          <div
            className={`inline-flex relative h-[23px] w-[23px] sm:mx-[35px] mx-2 mt-1 ${
              userScope?.alerts?.read
                ? "cursor-pointer"
                : "cursor-not-allowed opacity-50"
            } `}
            onClick={() => {
              if (userScope?.alerts?.read) {
                setShowAlertDropDown(!showAlertDropDown);
              }
            }}
            onMouseLeave={() => {
              if (userScope?.alerts?.read) {
                setShowAlertDropDown(false);
              }
            }}
          >
            {!isNotificationLoading &&
              unreadCount?.data?.count > 0 &&
              userScope?.alerts?.read && (
                <span
                style={{backgroundColor:theme.primaryColor}}
                  className={`absolute -right-1 -top-2 leading-4 rounded-full  w-4 h-4 p-0 m-0 text-white font-mono text-xvs text-center ${
                    unreadCount?.data?.count < 100 ? " text-xs " : "text-xvs "
                  }`}
                >
                  {unreadCount?.data?.count < 100
                    ? unreadCount?.data?.count
                    : "99+"}
                </span>
              )}
            <NotificationIcon />
            {showAlertDropDown === true ? (
              <NotificationDropdown
                navigate={navigate}
                notificationRefetch={notificationRefetch}
                unreadRefetch={unreadCountRefetch}
                data={notificationsData?.pages[0]?.response?.data?.data}
              />
            ) : null}
          </div>
          <div
            className="flex cursor-pointer relative "
            onMouseLeave={() => setAccountShowDropDown(false)}
            onClick={() => setAccountShowDropDown(!showAccountDropDown)}
          >
            <img
              src={userData?.profileImg || userIcon}
              className="rounded-full h-9  w-10 object-cover ltr:mr-[8px] rtl:ml-[8px]"
              alt="user-icon"
            />
            <h1 className="font-nunito my-auto ltr:mr-[31px] rtl:ml-[31px] hidden sm:inline-block font-bold">
              {userData.name}
            </h1>
            <div style={{ width: 200 }} className={style.accountDropDown}>
              <h1
                className="m-3 cursor-pointer font-semibold text-sm"
                onClick={() => {
                  navigate("/app/header/change-profile");
                }}
              >
                {t("_My_profile")}
              </h1>
              {loggedInRole !== "PA" && (
                <h1
                  className="m-3 cursor-pointer font-semibold text-sm"
                  onClick={() => {
                    navigate(`/app/header/billing`);
                  }}
                >
                  {t("_Billing")}
                </h1>
              )}
              {loggedInRole == "EA" || loggedInRole == "EU" ? null : (
                <h1
                  className="m-3 cursor-pointer font-semibold text-sm"
                  onClick={() => {
                    navigate(`/app/header/settings/${groupID}`);
                  }}
                >
                  {t("_Settings")}
                </h1>
              )}
              <h1
                className="m-3 cursor-pointer font-semibold text-sm"
                onClick={() => {
                  logout();
                }}
              >
                {t("_Log_out")}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
