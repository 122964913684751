let { origin } = window.location;

const urlConfig = {
  homePage: {
    PA: `${origin}/Admin/Modifying_My_Profile.htm`,
    EU: `${origin}/User/Web-based_Operations/Viewing_Home_Page.htm`,
    EA: `${origin}/Account/Web-based_Operations/Viewing_Home_Page.htm`,
  },
  usageReports: {
    PA: `${origin}/Admin/Generating_Reports.htm`,
    EU: `${origin}/Account/Reports.htm`,
    EA: `${origin}/Account/Reports.htm`,
  },
  insightsDashboard: {
    PA: `${origin}/Admin/Home_Page.htm`,
    EU: `${origin}/User/Web-based_Operations/Viewing_Dashboard.htm`,
    EA: `${origin}/Account/Web-based_Operations/Viewing_Dashboard.htm`,
  },
  smsSenderAddress: {
    PA: `${origin}/Admin/Managing_Sender_IDs.htm`,
    EU: `${origin}/User/Web-based_Operations/Managing_Sender_Addresses.htm`,
    EA: `${origin}/Account/Web-based_Operations/Managing_Sender_Addresses.htm`,
  },
  smsSenderAddressPA: {
    PA: `${origin}/Admin/Managing_Sender_IDs.htm`,
    EU: `${origin}/User/Web-based_Operations/Managing_Sender_Addresses.htm`,
    EA: `${origin}/Account/Web-based_Operations/Managing_Sender_Addresses.htm`,
  },
  smsTemplates: {
    PA: `${origin}/Admin/Viewing_Templates.htm`,
    EU: `${origin}/User/Web-based_Operations/Managing_Message_Templates/Viewing_Template.htm`,
    EA: `${origin}/Account/Web-based_Operations/Managing_Message_Templates/Managing_Message_Templates.htm`,
  },
  smsCampaigns: {
    PA: `${origin}/Admin/Campaign_Management.htm`,
    EU: `${origin}/User/Web-based_Operations/Managing_Campaigns.htm`,
    EA: `${origin}/Account/Web-based_Operations/Managing_Campaigns.htm`,
  },
  smppSession: {
    PA: `${origin}/Admin/Campaign_Management.htm`,
    EU: `${origin}/Admin/Campaign_Management.htm`,
    EA: `${origin}/Account/Managing_SMPP.htm`,
  },
  voiceCampaign: {
    PA: `${origin}/Admin/Viewing_Voice_Campaigns.htm`,
    EU: `${origin}/User/Viewing_Voice_Campaigns.htm`,
    EA: `${origin}/Account/Web-based_Operations/Managing_Message_Templates/Voice_Management.htm`,
  },
  voiceFileManager: {
    PA: "",
    EU: `${origin}/User/Web-based_Operations/Campaign_Management/Uploading_Audio_Files.htm`,
    EA: "",
  },
  whatsappProfile: {
    PA: `${origin}/Admin/Viewing_WhatsApp_Profile.htm`,
    EU: `${origin}/User/Web-based_Operations/WhatsApp_Profile_Management.htm`,
    EA: `${origin}/Account/Web-based_Operations/WhatsApp_Profile_Management/WhatsApp_Profile_Management.htm`,
  },
  whatsappProfilePA: {
    PA: `${origin}/Admin/Viewing_WhatsApp_Profile.htm`,
    EU: `${origin}/User/Web-based_Operations/WhatsApp_Profile_Management.htm`,
    EA: `${origin}/Account/Web-based_Operations/WhatsApp_Profile_Management/WhatsApp_Profile_Management.htm`,
  },
  whatsappTemplates: {
    PA: `${origin}/Admin/Viewing_WhatsApp_Templates_List.htm`,
    EU: `${origin}/User/Web-based_Operations/WhatsApp_Template_Management.htm`,
    EA: `${origin}/Account/Web-based_Operations/WhatsApp_Template_Management%2FWhatsApp_Template_Management.htm`,
  },
  whatsAppCampaign: {
    PA: `${origin}/Admin/Viewing_WhatsApp_Campaign_List.htm`,
    EU: `${origin}/User/Web-based_Operations/WhatsApp_Campaign_Management.htm`,
    EA: `${origin}/Account/Web-based_Operations/WhatsApp_Campaign_Management/WhatsApp_Campaign_Management.htm`,
  },
  studioDashboard: {
    EU: `${origin}/docs/StudioEU/Studio/Studio_Operations/Studio_Operations.htm`,
    EA: `${origin}/docs/StudioEA/Web-based_Operations/Studio/Studio_Operations/Studio_Operations.htm`,
  },
  packages: {
    PA: `${origin}/Admin/Package_Management.htm`,
    EU: `${origin}/User/Buying_Packages_-_SOB.htm`,
    EA: `${origin}/Account/Buying_Packages_-_SOB.htm`,
  },
  ratesheets: {
    PA: `${origin}/Admin/Rate_Sheet_Management.htm`,
    EU: `${origin}/User/Viewing_Rate_List.htm`,
    EA: `${origin}/Account/Viewing_Rate_List.htm`,
  },
  roles: {
    PA: `${origin}/Admin/Creating_Roles.htm`,
    EU: `${origin}/User/Role_Management.htm`,
    EA: `${origin}/Account/Role_Management.htm`,
  },
  users: {
    PA: `${origin}/Admin/managing_cp_users.htm`,
    EU: `${origin}/User/Managing_Users.htm`,
    EA: `${origin}/Account/Managing_Users.htm`,
  },
  distributionList: {
    PA: "",
    EU: `${origin}/User/Management_of_Distribution_List.htm`,
    EA: `${origin}/Account/Management_of_Distribution_List.htm`,
  },
  contactList: {
    PA: "",
    EU: `${origin}/User/Web-based_Operations%2FManaging_Contacts/Creating_Contact.htm`,
    EA: `${origin}/Account/Web-based_Operations/Managing_Contacts/Managing_Contacts.htm`,
  },
  contactGroup: {
    PA: "",
    EU: `${origin}/User/Web-based_Operations/Managing_Groups/Managing_Groups.htm`,
    EA: `${origin}/Account/Web-based_Operations/Managing_Groups/Managing_Groups.htm`,
  },
  segmentation: {
    PA: "",
    EU: "",
    EA: "",
  },
  logsActivity: {
    PA: `${origin}/Admin/Viewing_Platform_Admin_Activity_Logs.htm`,
    EU: `${origin}/User/Viewing_Activities.htm`,
    EA: `${origin}/Account/Viewing_Activities.htm`,
  },
  logsDebug: {
    PA: `${origin}/Admin/Viewing_Logs.htm`,
    EU: `${origin}/User/Viewing_Logs.htm`,
    EA: `${origin}/Account/Viewing_Logs.htm`,
  },
  logsEnterprise: {
    PA: `${origin}/Admin/Viewing_Enterprise_Logs.htm`,
    EU: "",
    EA: "",
  },
  notificationPA: {
    PA: `${origin}/Admin/Viewing_Alerts.htm`,
    EU: `${origin}/User/Creating_Alerts.htm`,
    EA: `${origin}/Account/Creating_Alerts.htm`,
  },
  numbersEA: {
    PA: `${origin}/Admin/Managing_Candidates.htm`,
    EU: `${origin}/User/Web-based_Operations/Managing_Numbers.htm`,
    EA: `${origin}/Account/Viewing_Number_List.htm`,
  },
  reportsPA: {
    PA: `${origin}/Admin/Generating_Reports.htm`,
    EU: "",
    EA: "",
  },
  notification: {
    PA: `${origin}/Admin/Configuring_Notification_Messages.htm`,
    EU: `${origin}/User/Viewing_Notifications.htm`,
    EA: `${origin}/Account/Viewing_Notifications.htm`,
  },
  numbersPA: {
    PA: `${origin}/Admin/Managing_Candidates.htm`,
  },
  rateSheetPA: {
    PA: `${origin}/Admin/Rate_Sheet_Management.htm`,
  },
  packagesPA: {
    PA: `${origin}/Admin/Package_Management.htm`,
  },
  walletsPA: {
    PA: `${origin}/Admin/Transferring_Credits.htm`,
  },
  invoicingPA: {
    PA: ``,
  },
  accountsPA: {
    PA: `${origin}/Admin/Account_List.htm`,
  },
  studioEditor: {
    EU: `${origin}/docs/StudioEU/Studio/Studio_Components/Canvas/Canvas.htm`,
    EA: `${origin}/docs/StudioEA/Web-based_Operations/Studio/Studio_Components/Canvas/Canvas.htm`,
  },
  studioEditorLogs: {
    EU: `${origin}/docs/StudioEU/Studio/Studio_Components/Logs.htm`,
    EA: `${origin}/docs/StudioEA/Web-based_Operations/Studio/Studio_Components/Logs.htm`,
  },
  emailCampaigns: {
    PA: `${origin}/Admin/Viewing_Email_Campaign_List.htm`,
    EU: `${origin}/User/Viewing_Email_Campaigns.htm`,
    EA: `${origin}/Account/Web-based_Operations/Pushing_Messages/Viewing_Email_Campaigns.htm`,
  },
  emailTemplates: {
    PA: `${origin}/Admin/Viewing_Email_Template_List.htm`,
    EU: `${origin}/User/Web-based_Operations/Managing_Message_Templates/Managing_Email_Templates.htm`,
    EA: `${origin}/Account/Web-based_Operations/Managing_Message_Templates/Managing_Email_Templates.htm`,
  },
  emailAddressEA: {
    PA: ``,
    EU: ``,
    EA: `${origin}/Account/Web-based_Operations/Managing_Email_IDs.htm`,
  },
  providersPA: {
    PA: `${origin}/Admin/index.htm#t=Managing_Providers.htm`,
    EU: ``,
    EA: ``,
  },
  routersPA: {
    PA: `${origin}/Admin/Creating_Rules.htm`,
    EU: ``,
    EA: ``,
  },
  emailAddressPA: {
    PA: `${origin}/Admin/index.htm#t=Viewing_Address_Specification.htm`,
    EU: ``,
    EA: ``,
  },
  quietTime: {
    PA: `${origin}/Admin/Viewing_Quiet_Time_Storgae_List.htm`,
    EU: ``,
    EA: `${origin}/Account/Web-based_Operations/Creating_Quiet_Time_Storage.htm`,
  },
  contentFilter: {
    PA: `${origin}/Admin/Configuring_Filter_Keywords.htm`,
    EU: ``,
    EA: ``,
  },
  blacklist: {
    PA: `${origin}/Admin/Black_Lists.htm`,
    EU: ``,
    EA: `${origin}/Account/Web-based_Operations/Black_Lists.htm`,
  },
  dnd: {
    PA: `${origin}/Admin/Deleting_DND_Numbers.htm`,
    EU: ``,
    EA: `${origin}/Account/Web-based_Operations/Creating_DND_Categories.htm`,
  },
};

export default urlConfig;
