import getAPIMap from "../api_map.js";
import axios from "axios";
import getIP from "../getIP.js";
const IP = getIP();

export async function getGlobalConfigNoAuth() {
  var url = IP + getAPIMap("getGlobalConfigNoAuth");
  try {
    let res = await axios.get(url);
    return res.data?.data;
  } catch (error) {
    console.error("Failed to fetch no-auth config:", error);
    throw error;
  }
}
