import getAPIMap from "../api_map.js";
import axios from "axios";
import getIP from "../getIP.js";
const IP = getIP();

/**
 * This method is used to get the balance amount of user
 * @param {*} param0
 * @returns
 */
export const getBalance = async ({ queryKey }) => {
  const config = {
    headers: {
      Authorization: `bearer ${queryKey[1].token}`,
    },
  };
  var url = IP + getAPIMap("getWalletBalance");
  url = url.replace("{userId}", queryKey[2]);
  let response = await axios.get(url, config);
  return response;
};

/**
 * This method is used to get the balance amount of logged in user
 * @param {*} param0
 * @returns
 */
export const getMyBalance = async ({ queryKey }) => {
  const config = {
    headers: {
      Authorization: `bearer ${queryKey[1].token}`,
    },
  };
  var url = IP + getAPIMap("getMyBalance");
  let response = await axios.get(url, config);
  return response;
};

/**
 * This method is used to credit amount by PA to EA
 * @param {*} id
 * @param {*} amount
 * @param {*} userDetails
 * @returns
 */
export const creditAmount = async (id, amount, userDetails, ponumber = "") => {
  const config = {
    headers: {
      Authorization: `bearer ${userDetails.token}`,
    },
  };

  // var url = IP + `/ngage_accounts/api/v3/accounts/users/${uid}`;
  var url = IP + getAPIMap("getCredit");
  var obj = { userId: id, amount: amount, txnId: ponumber };
  let response = await axios.post(url, obj, config);
  return response;
};

//airtel money
export const paymentGateway = async (token, amount, phoneNum) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  var url = IP + getAPIMap("paymentGateway");
  const body = {
    amount: amount,
    msisdn: phoneNum,
  };
  let response = await axios.post(url, body, config);
  return response;
};

export const getUsers = async ({ queryKey }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${queryKey[1].token}`,
    },
  };
  var url = IP + getAPIMap("getuser");

  let response = await axios.get(url, config);
  return response;
};

/**
 * This method is used to debit amount from EA by PA
 * @param {*} id
 * @param {*} amount
 * @param {*} userDetails
 * @returns
 */
export const debitAmount = async (id, amount, userDetails, ponumber = "") => {
  const config = {
    headers: {
      Authorization: `bearer ${userDetails.token}`,
    },
  };
  var url = IP + getAPIMap("debit");
  var obj = { userId: id, amount: amount, txnId: ponumber };
  try {
    let response = await axios.post(url, obj, config);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * This method is used to transfer amount from EA to EU
 * @param {*} srcId
 * @param {*} destId
 * @param {*} amount
 * @param {*} userDetails
 * @returns
 */

export const transferAmount = async ({
  srcId,
  destId,
  amount,
  userDetails,
}) => {
  const config = {
    headers: {
      Authorization: `bearer ${userDetails.token}`,
    },
  };
  var url = IP + getAPIMap("transfer");
  var obj = { srcUser: srcId, destUser: destId, amount: amount };
  try {
    let response = await axios.post(url, obj, config);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export async function getEnterpriseList({ queryKey }) {
  const config = {
    headers: {
      Authorization: `Bearer ${queryKey[1].token}`,
    },
  };

  var url = IP + getAPIMap("listAccounts");
  let response = await axios.get(url, config);

  return response;
}

export async function getBuffer({ queryKey }) {
  const config = {
    headers: {
      Authorization: `Bearer ${queryKey[1].token}`,
    },
  };

  let url = IP + getAPIMap("getBuffer");
  url = url.replace("{userId}", queryKey[1].sub);
  let response = await axios.get(url, config);

  return response;
}

export const updateBuffer = async ({ userId, buffer, token }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const url = IP + getAPIMap("updateBuffer");
  const body = { buffer, userId };

  const response = await axios.put(url, body, config);
  return response;
};
