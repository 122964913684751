import { useContext } from "react";
import { Navigate } from "react-router-dom";
import Auth from "../../containers/Auth";
import { AuthContext } from "../../context/AuthContext";
import { decodingJWT } from "../../utils/jwtDecode";
import { useQuery } from "react-query";
import getAPIMap from "../../api_map";
import axios from "axios";
import getIP from "../../getIP.js";
const IP = getIP();
import { t } from "i18next";

export default function ForwardRoute() {
  const { user } = useContext(AuthContext);

  const { data, isLoading } = useQuery(
    ["/menu", user],
    async () => {
      const url = IP + getAPIMap("menu");

      const config = {
        headers: {
          Authorization: `Bearer ${user?.data?.access_token}`,
        },
      };
      return await axios.get(url, config);
    },
    { enabled: user ? true : false }
  );

  const filteredMenu = data?.data?.data?.filter(
    (e) => Object.keys(e)?.length > 0
  );

  const firstMenu = filteredMenu?.[0]?.subRoutes?.length
    ? filteredMenu?.[0]?.subRoutes?.[0]?.path
    : filteredMenu?.[0]?.path;

  const getRoleName = (user) => {
    let roleName = "";
    roleName = decodingJWT(user?.data?.access_token);
    roleName = roleName?.realm_access?.roles?.[0]?.split("~")?.[2] || "";
    return roleName;
  };

  return (
    <>
      {user && !isLoading && firstMenu ? (
        <Navigate
          to={getRoleName(user) !== t("_platform_Admin") ? "/app/home" : firstMenu}
        />
      ) : (
        <Auth />
      )}
    </>
  );
}
